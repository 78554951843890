import  * as React from 'react';
import { useEffect, useState } from 'react';
import { Tabs, Tab, Box, Grid, Divider, IconButton} from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import csv from '../../images/csv.png';
import Sidebar from '../../Sidebar'
import Footer from '../../Footer';
import './styles/FuelReports.scss'
import CurrentFuelPrices from './FuelDashboard/CurrentFuelPrices';
import FuelComparison from './FuelPerformance/FuelPerformance';
import FuelStations from './FuelStations/FuelStations';
import DriveOffTable from './FuelDashboard/DriveOffs';
import DriveoffReport from './DriveOffReport/DriveoffReport';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
}
function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}

const FuelReports: React.FC = () => {
    const [value, setValue] = React.useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    const handleExportCSV = () => {
        // Logic to export sales data to CSV
        console.log('Export as CSV');
    };

    const handleExportPDF = () => {
        // Logic to export sales data to PDF
        console.log('Export as PDF');
    };
    return(
        <div className='fuelContent'>
            <Grid container>
                <Grid item xs={12} md={2}>
                    <div>
                        <Sidebar></Sidebar>
                    </div>   
                </Grid>
                <Grid item xs={12} md={10}>
                    <div className='contentF'>
                    <div className='pageHeader'><h1>Fuel Reports</h1></div>
                    <div className='pageContent'>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Dashboard" {...a11yProps(0)} />
                            <Tab label="Performance" {...a11yProps(1)} />
                            <Tab label="Stations" {...a11yProps(2)} />
                            <Tab label='Drive Offs' {...a11yProps(3)}/>
                        </Tabs>
                        </Box>
                        <CustomTabPanel value={value} index={0} >
                            <CurrentFuelPrices></CurrentFuelPrices>
                            <Divider></Divider>
                            <br/>
                            <DriveOffTable></DriveOffTable>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1} >
                            <FuelComparison></FuelComparison>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={2} >
                            <div className='stationsLive'>
                            <FuelStations></FuelStations>
                            </div>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={3}>
                            <DriveoffReport/>
                        </CustomTabPanel>
                    </div>
                    </div>
                </Grid>
            </Grid>
            <Footer></Footer>
        </div>
    );
};
export default FuelReports