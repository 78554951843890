import  * as React from 'react';
import { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Select, Tabs, Tab, Button, MenuItem, CircularProgress, Box, Grid, Paper, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Sidebar from '../../Sidebar'
import Footer from '../../Footer';
import './styles/SalesHistory.scss'
import SalesBarChart from '../../SalesBarChart';
import ViewComparisons from './ViewComparisons';
import csv from '../../images/csv.png';
import  jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';  // For table support in jsPDF
import html2canvas from 'html2canvas';

interface SalesData {
  date: string;
  total_sales: number;
  total_quantity: number;
}

interface ApiResponse {
  total: number;
  pages: number;
  current_page: number;
  data: SalesData[];
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
  
function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
}
  
function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}


const SalesHistory: React.FC = () => {
    
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    const [salesData, setSalesData] = useState<SalesData[]>([]);
    const [perPage, setPerPage] = useState(20);
    const [periodType, setPeriodType] = useState<string>('daily');
    const [site, setSite] = useState<string>('all');
    const [page, setPage] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [exportPerPage, setExportPerPage] = useState(20); // Per page for CSV export
    let url = `${process.env.REACT_APP_API_URL}/api/sales-history?period_type=${periodType}&site=${site}&page=${page}&per_page=${perPage}`;
    useEffect(() => {
        const fetchData = async (startDate?: string, endDate?: string) => {
            setLoading(true);
            try {

                if (startDate && endDate) {
                    url += `?start_date=${startDate}&end_date=${endDate}`;
                }
                const response = await fetch(url);
                const result: ApiResponse = await response.json();
                setSalesData(result.data);
            } catch (error) {
                if (error instanceof Error) {
                    setError(error.message);
                }
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [periodType, site, page]);

    const handleDialogOpen = () => {
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
    };
    const handleExportCSV = async () => {
        const csvUrl = `${process.env.REACT_APP_API_URL}/api/sales-history?period_type=${periodType}&site=${site}&page=1&per_page=${exportPerPage}`;
        try {
            const response = await fetch(csvUrl);
            const result: ApiResponse = await response.json();
            const csvData = result.data;

        if (csvData.length === 0) {
            alert("No data to export.");
            return;
        }
        const csvHeader = ['Sale Date', 'Total Sales (GBP)', 'Total Quantity'];
        const csvRows = csvData.map(item => [
            item.date,
            item.total_sales,
            item.total_quantity,
        ]);

        // Create CSV content
        let csvContent = `${csvHeader.join(',')}\n`; // Add header row
        csvRows.forEach(row => {
            csvContent += row.join(',') + '\n'; // Add each row of data
        });

        // Create a blob and download the CSV file
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `sales_data_${new Date().toLocaleDateString()}.csv`); // File name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Clean up
        } catch (error) {
            console.error("Failed to export CSV", error);
        } finally {
            handleDialogClose();
        }
    };

    const handleExportPDF = async () => {
        const pdf = new jsPDF();
        const title = 'Sales History Report';
        pdf.text(title, 14, 20);
    
        const pdfUrl = `${process.env.REACT_APP_API_URL}/api/sales-history?period_type=${periodType}&site=${site}&page=1&per_page=${exportPerPage}`;
        
        try {
            const response = await fetch(pdfUrl);
            const result: ApiResponse = await response.json();
            const salesData = result.data;
    
            if (salesData.length === 0) {
                alert("No data to export.");
                return;
            }
    
            // Add table headers
            const headers = ['Sale Date', 'Total Sales (GBP)', 'Total Quantity'];
            
            // Format table rows with sales data
            const rows = salesData.map((item) => [
                item.date,
                new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(item.total_sales),
                item.total_quantity,
            ]);
    
            // Add table to the PDF using autoTable
            autoTable(pdf,{
                head: [headers],
                body: rows,
                startY: 30, // Adjust where the table should start
            });
            
            const chartElement = document.querySelector('.barChart') as HTMLElement;
            if (chartElement) {
            const canvas = await html2canvas(chartElement);
            const imgData = canvas.toDataURL('image/png');
            
            // Add the chart image to the PDF
            pdf.addPage();
            pdf.text('Sales History Bar Chart', 14, 20);
            pdf.addImage(imgData, 'PNG', 14, 30, 180, 180); // Adjust width and height as needed
        }
    
            // Save the PDF
            pdf.save(`sales_history_${new Date().toLocaleDateString()}.pdf`);
        } catch (error) {
            console.error("Failed to export PDF", error);
        }
    };
    const handleExportPDFComparison = async () => {
        const pdf = new jsPDF();
        const title = 'Sales Category Comparisons Report';
        pdf.text(title, 14, 20);

        const chartElement = document.querySelector('.comparisonsPage') as HTMLElement;
        const originalBackground = chartElement.style.backgroundColor;
        chartElement.style.backgroundColor = '#fff';
        chartElement.style.boxShadow = 'none';
        chartElement.style.opacity = '1';
        
        const canvas = await html2canvas(chartElement, {
            scale: 3,
            useCORS: true,
            backgroundColor: '#fff',
        });
        chartElement.style.backgroundColor = originalBackground;
        const imgData = canvas.toDataURL('image/png');
        pdf.addImage(imgData, 'PNG', -10, 25, 220, 240); // Adjust width and height as needed
        pdf.save(`sales_Category_Comparisons_${new Date().toLocaleDateString()}.pdf`);
    };

return (
  <div className='salesContent'>
    <Grid container>
        <Grid item xs={12} md={2}>
            <div>
                <Sidebar></Sidebar>
            </div>   
        </Grid>
        <Grid item xs={12} md={10}>
            <div className='contentS'>
                <div className='pageHeader'><h1>Sales History</h1></div>
                <div className='pageContent'>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="View Sales" {...a11yProps(0)} />
                            <Tab label="View Comparisons" {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                
                    <CustomTabPanel value={value} index={0} >
                        <div className='viewSalesTab'>
                            <div className='exportButtons'>
                                <IconButton onClick={handleDialogOpen} aria-label="Export as CSV">
                                    <img src={csv} className='csvIcon'/>
                                </IconButton>
                                <IconButton onClick={handleExportPDF} aria-label="Export as PDF">
                                    <PictureAsPdfIcon sx={{fontSize:"30px"}}/>
                                </IconButton>
                            </div>
                            <br/>
                        <div className='choices'>
                                <div className='periodChoice'>
                                    <p>Period Type:</p>
                                    <Select value={periodType} onChange={(e) => setPeriodType(e.target.value)}>
                                        <MenuItem value="daily">Daily</MenuItem>
                                        <MenuItem value="weekly">Weekly</MenuItem>
                                        <MenuItem value="monthly">Monthly</MenuItem>
                                    </Select>
                                </div>
                                <div className='SiteChoice'>
                                    <p>Filter by Site:</p>
                                    <Select value={site} onChange={(e) => setSite(e.target.value)}>
                                        <MenuItem value="all">ALL</MenuItem>
                                        <MenuItem value="19191">Autoport</MenuItem>
                                        <MenuItem value="24469">Dumbarton Rd</MenuItem>
                                        <MenuItem value="24580">Strathclyde</MenuItem>
                                        <MenuItem value="24796">Rutherglen</MenuItem>
                                        <MenuItem value="24897">Sauchiehall</MenuItem>
                                        <MenuItem value="24923">Braeside</MenuItem>
                                        <MenuItem value="24974">Coatbridge</MenuItem>
                                        <MenuItem value="24753">Hillington</MenuItem>
                                        <MenuItem value="24701">Bearsden</MenuItem>
                                        <MenuItem value="24922">Bridgeton</MenuItem>
                                        <MenuItem value="25251">Wishaw</MenuItem>
                                        <MenuItem value="24195">Head Office</MenuItem>
                                    </Select>
                                </div> 
                                
                        </div>
                            <Grid container>
                                <Grid item xs={12} md={6}>
                                    
                                    <div className='table'>
                                        <Table className='tableContent'>
                                            <Table stickyHeader>
                                            <TableHead className='tableHead'>
                                                <TableRow className='tableHead'>
                                                    <TableCell className='tableHead'>Sale Date</TableCell>
                                                    <TableCell className='tableHead'>Total Sales</TableCell>
                                                    <TableCell className='tableHead'>Total Quantity</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            {loading ? (
                                            <div className='loading'>
                                                <CircularProgress />
                                            </div>
                                            ) : (
                                            <TableBody>
                                                {salesData.map((item) => (
                                                    <TableRow key={item.date}>
                                                        <TableCell className='dateColumn'>{item.date}</TableCell>
                                                        <TableCell className='salesColumn'>
                                                        {new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(item.total_sales)}
                                                            </TableCell>
                                                        <TableCell className='quantityColumn'>{item.total_quantity}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                            )}
                                            </Table>
                                        </Table> 
                                    </div>
                                    <div className='pageButtons'>
                                            <Button onClick={() => setPage(page - 1)} disabled={page === 1}>
                                                    Previous
                                            </Button>
                                            <Button onClick={() => setPage(page + 1)}>
                                                    Next
                                            </Button>
                                    </div>   
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Paper className='chartContainer'>
                                     <div className='barChart'>
                                              <SalesBarChart salesData={salesData} />
                                    </div>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </div> 
                    </CustomTabPanel >
                    <CustomTabPanel value={value} index={1}>
                        <div className='exportButtons'>
                            <IconButton onClick={handleExportPDFComparison} aria-label="Export as PDF">
                                <PictureAsPdfIcon sx={{fontSize:"30px"}}/>
                            </IconButton>
                        </div>
                        <br/>
                        <div className='comparisonsPage'>
                        <ViewComparisons></ViewComparisons>
                        </div>
                    </CustomTabPanel>
                </div>
            </div>
        </Grid>
    </Grid>
    <Footer></Footer>
    {/* CSV Export Dialog */}
    <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>Export as CSV</DialogTitle>
        <DialogContent>
            <DialogContentText>
                Please enter how many rows you would like to export:
            </DialogContentText>
            <TextField
                autoFocus
                margin="dense"
                label="Rows to Export"
                type="number"
                fullWidth
                variant="standard"
                value={exportPerPage}
                onChange={(e) => setExportPerPage(Number(e.target.value))}
            />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDialogClose}>Cancel</Button>
                <Button onClick={handleExportCSV}>Export</Button>
            </DialogActions>
    </Dialog>
    </div>
    );
};

export default SalesHistory