import { useState, useEffect } from "react";
import axios from "axios";
import "./drive-off-report.scss";
import Loader from "../../../loader/Loader";
import DriveoffRow from "./DriveoffRow";
import moment from "moment";
import { numberValidationFloat } from "../../../../utils/validation";
//MUI
import { Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from "@mui/icons-material/Refresh";
import Tooltip from "@mui/material/Tooltip";

export default function DriveoffReport() {
    const url = process.env.REACT_APP_NITROCAM_URL;
    //Drive Offs
    const [fixedDriveOffs, setFixedDriveOffs] = useState<any[]>([]);
    const [displayedDriveOffs, setDisplayedDriveOffs] = useState<any[]>([]);
    //Sites
    const [selectedSite, setSelectedSite] = useState<string>("");
    //Statuses
    const [statuses, setStatuses] = useState<any[]>([]);
    const [selectedStatus, setSelectedStatus] = useState<number>(0);
    //Search fields
    const [valueSearch, setValueSearch] = useState<string>("");
    const [regSearch, setRegSearch] = useState<string>("");
    const [dateRangeStart, setDateRangeStart] = useState<string>("");
    const [dateRangeEnd, setDateRangeEnd] = useState<string>("");
    //Alerts & Loader
    const [disable, setDisable] = useState<boolean>(false);
    const [loader, setLoader] = useState<boolean>(false);

    useEffect(() => {
        fetchDriveOffs();
        fetchStatuses();
    }, []);

    const fetchDriveOffs = async () => {
        setLoader(true);
        let _driveoffs: any[] = [];
        let _nmop: any[] = [];

        await axios
            .post(`${url}/cctv-system/submissions/details`, { requestType: 1 })
            .then((res) => {
                _driveoffs = res.data;
                setLoader(false);
            })
            .catch((err) => {
                console.log(err);
                setLoader(false);
            });

        await axios
            .post(`${url}/cctv-system/submissions/details`, { requestType: 4 })
            .then((res) => {
                _nmop = res.data;
                setLoader(false);
            })
            .catch((err) => {
                console.log(err);
                setLoader(false);
            });

        //Concat the drive off and nmop array and then sort all by date
        const _joinedArrays = _driveoffs.concat(_nmop);
        const _sortedArray = _joinedArrays.sort((a, b) => {
            return new Date(b.date).getTime() - new Date(a.date).getTime();
        });
        setFixedDriveOffs(_sortedArray);
        setDisplayedDriveOffs(_sortedArray);
    };

    const fetchStatuses = async () => {
        await axios
            .get(`${url}/cctv-system/statuses`)
            .then((res) => {
                setStatuses(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleRefresh = (): void => {
        setDisable(true);
        setDateRangeStart("");
        setDateRangeEnd("");
        setRegSearch("");
        setSelectedStatus(0);
        setSelectedSite("");
        setDisable(false);
        fetchDriveOffs();
    };
    const clearFilters = (): void => {
        setSelectedStatus(0);
        setSelectedSite("");
    };

    const clearSearchFields = (): void => {
        setRegSearch("");
        setValueSearch("");
    };

    const handleFilter = (value: any, field: string): void => {
        setDateRangeStart("");
        setDateRangeEnd("");
        let filterData = {
            pinNumber: field === "site" ? value : selectedSite,
            statusId: field === "status" ? value : selectedStatus,
        };

        //Remove any key/value pairs from the filtered data where the value is 0 (default)
        const removeZeros = (item: any) =>
            Object.keys(item)
                .filter((key) => item[key] !== 0 && item[key] !== "")
                .reduce((newObj: any, key) => {
                    newObj[key] = item[key];
                    return newObj;
                }, {});
        const _result = removeZeros(filterData);

        //Create a new filter array of the key value pairs that have to be filtered
        let filteredArray: any = [];
        for (const [key, value] of Object.entries(_result)) {
            filteredArray.push({ key: key, value: value });
        }

        //Loop through filter array and set new array
        let finalFiltered = fixedDriveOffs;
        for (let index = 0; index < filteredArray.length; index++) {
            finalFiltered = finalFiltered.filter(
                (order: any) =>
                    order[filteredArray[index].key] ==
                    filteredArray[index].value
            );
        }

        //Set Current filter selects
        if (field === "site") {
            setSelectedSite(value);
        } else if (field === "status") {
            setSelectedStatus(value);
        }
        //Set new displayed requests
        setDisplayedDriveOffs(finalFiltered);
    };

    const handleDateSearch = (date: string, type: string): void => {
        clearFilters();
        clearSearchFields();
        //Determine if date change is the start or end
        if (type === "start") {
            setDateRangeStart(date);
            //If end date set then do lookup
            if (dateRangeEnd !== "") {
                let _filteredArray = fixedDriveOffs.filter(
                    (request: any, i: number) => {
                        let _formattedDate = moment(request.date).format(
                            "YYYY-MM-DD"
                        );
                        return (
                            _formattedDate >= date &&
                            _formattedDate <= dateRangeEnd
                        );
                    }
                );
                setDisplayedDriveOffs(_filteredArray);
            }
        } else {
            setDateRangeEnd(date);
            //If start date set then do lookup
            if (dateRangeStart !== "") {
                let _filteredArray = fixedDriveOffs.filter(
                    (request: any, i: number) => {
                        let _formattedDate = moment(request.date).format(
                            "YYYY-MM-DD"
                        );
                        return (
                            _formattedDate >= dateRangeStart &&
                            _formattedDate <= date
                        );
                    }
                );
                setDisplayedDriveOffs(_filteredArray);
            }
        }
    };

    const handleRegCheck = (): void => {
        //Will clear filter then check reg entered against request reg in lower case
        clearFilters();
        setDateRangeStart("");
        setDateRangeEnd("");
        setValueSearch("");
        let _lowerCaseReg = regSearch.toLowerCase();
        let _filteredArray: any = fixedDriveOffs.filter(
            (request: any, i: number) => {
                return (
                    request.plate &&
                    request.plate.toLowerCase().includes(_lowerCaseReg)
                );
            }
        );
        setDisplayedDriveOffs(_filteredArray);
    };

    const handleValueCheck = (): void => {
        clearFilters();
        setDateRangeStart("");
        setDateRangeEnd("");
        setRegSearch("");
        let _filteredArray: any = fixedDriveOffs.filter(
            (request: any, i: number) => {
                return request.amount && request.amount.includes(valueSearch);
            }
        );
        setDisplayedDriveOffs(_filteredArray);
    };

    return (
        <main className="drive-off-report">
            <section className="drive-off-lookup">
                <select
                    className="site-filter"
                    onChange={(e) => {
                        handleFilter(parseInt(e.target.value), "site");
                    }}
                    value={selectedSite}
                >
                    <option value="">Filter By Site</option>
                    <option value="19191">Autoport</option>
                    <option value="24796">Rutherglen</option>
                    <option value="24923">Braeside</option>
                    <option value="24701">Bearsden</option>
                    <option value="24922">Bridgeton</option>
                </select>
                <select
                    className="status-filter"
                    onChange={(e) => {
                        handleFilter(parseInt(e.target.value), "status");
                    }}
                    value={selectedStatus}
                >
                    <option value={0}>Filter By Status</option>
                    {statuses && statuses.length > 0
                        ? statuses.map((status: any, i: number) => {
                              return (
                                  <option key={i} value={status.statusId}>
                                      {status.name}
                                  </option>
                              );
                          })
                        : null}
                </select>

                <div className="single-search">
                    <input
                        type="text"
                        placeholder="Search Reg"
                        onChange={(e) => {
                            if (e.target.value === "") {
                                setDisplayedDriveOffs(fixedDriveOffs);
                            }
                            setRegSearch(e.target.value);
                        }}
                        value={regSearch}
                    />
                    <Button
                        color="primary"
                        variant="contained"
                        className="search-btn"
                        onClick={handleRegCheck}
                        disabled={disable || regSearch === ""}
                    >
                        <SearchIcon />
                    </Button>
                </div>
                <div className="single-search">
                    <input
                        type="number"
                        placeholder="Search Value"
                        onChange={(e) => {
                            if (e.target.value === "") {
                                setDisplayedDriveOffs(fixedDriveOffs);
                            }
                            setValueSearch(e.target.value);
                        }}
                        onKeyDown={(e) => numberValidationFloat(e)}
                        value={valueSearch}
                    />
                    <Button
                        color="primary"
                        variant="contained"
                        className="search-btn"
                        onClick={handleValueCheck}
                        disabled={disable || valueSearch === ""}
                    >
                        <SearchIcon />
                    </Button>
                </div>
                <div className="single-search">
                    <input
                        type="date"
                        placeholder="Search Date"
                        className="date-input"
                        onChange={(e) => {
                            if (e.target.value === "") {
                                setDisplayedDriveOffs(fixedDriveOffs);
                                setDateRangeStart("");
                            } else {
                                handleDateSearch(e.target.value, "start");
                            }
                        }}
                        value={dateRangeStart}
                        max={dateRangeEnd}
                    />
                    <span>-</span>
                    <input
                        type="date"
                        placeholder="Search Date"
                        className="date-input"
                        onChange={(e) => {
                            if (e.target.value === "") {
                                setDisplayedDriveOffs(fixedDriveOffs);
                                setDateRangeEnd("");
                            } else {
                                handleDateSearch(e.target.value, "end");
                            }
                        }}
                        value={dateRangeEnd}
                        min={dateRangeStart}
                    />
                </div>
                <Tooltip title="Refresh">
                    <Button
                        variant="contained"
                        color="warning"
                        onClick={handleRefresh}
                        disabled={disable}
                        className="cctv-btns"
                    >
                        <RefreshIcon />
                    </Button>
                </Tooltip>
            </section>
            {/* MAIN TABLE SECTION */}

            {!loader ? (
                displayedDriveOffs.length > 0 ? (
                    <table className="cctv-admin-table">
                        <thead>
                            <tr>
                                <th>Site</th>
                                <th>Submitted By</th>
                                <th>Date/Time</th>
                                <th>status</th>
                                <th>Reg</th>
                                <th>Amount</th>
                                <th>Crime Ref</th>
                                <th>Info</th>
                            </tr>
                        </thead>
                        <tbody>
                            {displayedDriveOffs.length > 0
                                ? displayedDriveOffs.map(
                                      (r: any, i: number) => {
                                          return (
                                              <DriveoffRow
                                                  key={i}
                                                  request={r}
                                              />
                                          );
                                      }
                                  )
                                : null}
                        </tbody>
                    </table>
                ) : (
                    <h2>No Requests</h2>
                )
            ) : (
                <Loader />
            )}
        </main>
    );
}
