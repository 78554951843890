import { useState } from "react";
import moment from "moment";
//MUI
import { Modal } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
//Componets
import DriveoffInfo from "./DriveoffInfo";

interface Props {
    request: any;
}

export default function DriveoffRow({ request }: Props) {
    const [openInfo, setOpenInfo] = useState<boolean>(false);
    return (
        <>
            <tr className={request.unactionIn7Days ? "not-actioned" : ""}>
                <td>{request.site}</td>
                <td>{request.staffName}</td>
                <td>
                    {moment(request.date).format("DD/MM/YY")} - {request.time}
                </td>

                <td>{request.statusName}</td>
                <td>{request.plate}</td>
                <td>£{request.amount}</td>
                <td>
                    {request.crimeReference ? (
                        request.crimeReference
                    ) : (
                        <DoDisturbIcon fontSize="large" color="error" />
                    )}
                </td>
                <td>
                    <InfoIcon
                        fontSize="large"
                        onClick={() => setOpenInfo(true)}
                        color="primary"
                        className="info-icon"
                    />
                </td>
            </tr>
            <Modal open={openInfo}>
                <DriveoffInfo setOpenInfo={setOpenInfo} request={request} />
            </Modal>
        </>
    );
}
