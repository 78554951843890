import React from 'react';
import Sidebar from '../Sidebar'
import '../styles/DashboardPage.scss';
import { Grid, Typography} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Footer from '../Footer';

const DashboardPage: React.FC = () => {
    const navigate = useNavigate();
    const openSalesH = () => {
        navigate('/sales-history');
      };
    const openStoresP = () => {
        navigate('/stores-perform');
    };
    const openProductsP = () => {
        navigate('/products-perform');
    };
    const openProductsT = () => {
        navigate('/products-turnover');
    };
    const openFuelR = () => {
        navigate('/fuel-reports');
    };
    return (
        <>
        <div className="dashboard">
            <Grid container>
                <Grid item xs={12} md={2}>
                    <div>
                        <Sidebar></Sidebar>
                    </div>   
                </Grid>
                <Grid item xs={12} md={10}>
                    <div className="content">
                        <div className='pageHeader'><h1>Welcome to the Business Dashboard</h1></div>
                        <div className='pageContent'>
                            <div className='cards'>
                                <div className='cardsHolder'>
                                    <div className='individialCards'>
                                        <Typography className='text'>SALES HISTORY</Typography>
                                        <button onClick={openSalesH}>View Data</button>
                                    </div>
                                    <div className='individialCards'>
                                        <Typography className='text'>STORES PERFORMANCE</Typography>
                                        <button onClick={openStoresP}>View Stores</button>
                                    </div>
                                </div>
                                <br/>
                                <div className='cardsHolder'>
                                    <div className='individialCards'>
                                        <Typography className='text'>PRODUCTS PERFORMANCE</Typography>
                                        <button onClick={openProductsP}>View Products</button>
                                    </div>
                                    <div className='individialCards'>
                                        <Typography className='text'>PRODUCTS TURNOVER</Typography>
                                        <button onClick={openProductsT}>View Turnover</button>
                                    </div>
                                </div>
                                <br/>
                                <div className='cardsHolder'>
                                    <div className='individialCards'>
                                        <Typography className='text'>FUEL REPORTS</Typography>
                                        <button onClick={openFuelR}>View Reports</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </Grid>
            </Grid>
            <Footer></Footer>
        </div>
        
        </>
    );
};

export default DashboardPage;