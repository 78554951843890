import { useState, useEffect } from "react";
import moment from "moment";
import axios from "axios";
//MUI
import { Box } from "@mui/material";
import { Button } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CloseIcon from "@mui/icons-material/Close";

//Styling
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1200,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    height: "80vh",
};
//Inferfaces
interface Props {
    setOpenInfo: any;
    request: any;
}
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

//MUI TABS SECTION START------------------
function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}
//MUI TABS SECTION END------------------

export default function DriveoffInfo({ request, setOpenInfo }: Props) {
    console.log(request);
    const url = process.env.REACT_APP_NITROCAM_URL;

    //Tabs
    const [value, setValue] = useState<number>(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    //Notes & Modal Tab Sections
    const [storeNotes, setStoreNotes] = useState<any[]>([]);
    const [adminNotes, setAdminNotes] = useState<any[]>([]);

    useEffect(() => {
        fetchNotes();
        fetchAdminNotes();
    }, []);

    const fetchNotes = async () => {
        await axios
            .get(`${url}/cctv-system/submissions/notes/${request.requestId}`)
            .then((res) => {
                setStoreNotes(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const fetchAdminNotes = async () => {
        await axios
            .get(`${url}/cctv-system/admin/notes/${request.requestId}`)
            .then((res) => {
                setAdminNotes(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <Box className="cctv-admin-info-modal" sx={style}>
            <p className="request-identifier">{request.requestId}</p>
            <h1>
                <span>{request.typeName}</span>
                <span className="info-site">{request.site}</span>
                <span className="info-date">
                    {moment(request.date).format("DD/MM/YY")}-{request.time}
                </span>
                <span className="info-staff">{request.staffName}</span>
                <span className="info-status"> {request.statusName}</span>
                <Button
                    variant="contained"
                    color="error"
                    onClick={() => setOpenInfo(false)}
                    className="close-btn"
                >
                    <CloseIcon />
                </Button>
            </h1>
            <section className="top-section">
                {request.requestType === 2 ? (
                    <>
                        <div className="info-section">
                            <h2>Description of Incident</h2>
                            <p>{request.description}</p>
                            <h2>Items Stolen</h2>
                            <p>
                                {request.items
                                    ? request.items
                                    : "No details provided"}
                            </p>
                        </div>
                        <div className="info-section">
                            <h2>People Involved</h2>
                            <p>
                                {request.person
                                    ? request.person
                                    : "No details provided"}
                            </p>
                            <div className="info-section-images">
                                <h2>Images</h2>
                                {request.images ? (
                                    request.images.map(
                                        (image: any, i: number) => {
                                            return (
                                                <img
                                                    src={image}
                                                    alt="CCTV Image"
                                                    key={i}
                                                />
                                            );
                                        }
                                    )
                                ) : (
                                    <p>No Images</p>
                                )}
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="info-section">
                            <h2>Make</h2>
                            <p>
                                {request.make ? request.make : "Not Available"}
                            </p>
                            <h2>MOT Status</h2>
                            <p>
                                {request.motStatus
                                    ? request.motStatus
                                    : "Not Available"}
                            </p>
                            <h2>Tax Status</h2>
                            <p>
                                {request.taxStatus
                                    ? request.taxStatus
                                    : "Not Available"}
                            </p>
                        </div>
                        <div className="info-section">
                            <h2>Registration (Year)</h2>
                            <p>
                                {request.plate
                                    ? request.plate
                                    : "Not Available"}{" "}
                                {request.yearOfManufacture
                                    ? `(${request.yearOfManufacture})`
                                    : "(No year Available)"}
                            </p>
                            <h2>Fuel Type</h2>
                            <p>
                                {request.fuelType
                                    ? request.fuelType
                                    : "Not Available"}
                            </p>
                            <h2>Pump Number</h2>
                            <p>
                                {request.pump ? request.pump : "Not Available"}
                            </p>
                        </div>
                        <div className="info-section">
                            <h2>Amount (£)</h2>
                            <p>
                                {request.amount
                                    ? `£${request.amount}`
                                    : "Not Available"}
                            </p>
                            <h2>Litres</h2>
                            <p>
                                {request.litres
                                    ? request.litres
                                    : "Not Available"}
                            </p>
                            <h2>Police Officer Dealing with case</h2>
                            <p>
                                {request.officerName
                                    ? request.officerName
                                    : "Not Available"}
                            </p>
                        </div>
                    </>
                )}
            </section>
            <section className="bottom-section">
                <Box sx={{ width: "100%" }} className="tab-box">
                    <Box
                        sx={{ borderBottom: 1, borderColor: "divider" }}
                        className="tab-menu-section"
                    >
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="Notes Tabs"
                        >
                            <Tab label="Store / Dev Notes" {...a11yProps(0)} />
                            <Tab label="Admin Notes" {...a11yProps(1)} />
                            <Tab label="Investigation" {...a11yProps(2)} />
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                        {storeNotes.length > 0 ? (
                            <div className="note-table-container">
                                <table className="note-table">
                                    <thead>
                                        <tr>
                                            <th>Submitted By</th>
                                            <th>Note</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {storeNotes.map(
                                            (note: any, i: number) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>{note.user}</td>
                                                        <td>{note.note}</td>
                                                        <td>
                                                            {moment(
                                                                note.noteDate
                                                            ).format(
                                                                "DD/MM/YY HH:mm"
                                                            )}
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            <h2 className="none-message">
                                There are no store/dev notes yet
                            </h2>
                        )}
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        {adminNotes.length > 0 ? (
                            <div className="note-table-container">
                                <table className="note-table">
                                    <thead>
                                        <tr>
                                            <th>Submitted By</th>
                                            <th>Note</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {adminNotes.map(
                                            (note: any, i: number) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>{note.user}</td>
                                                        <td>{note.note}</td>
                                                        <td>
                                                            {moment(
                                                                note.noteDate
                                                            ).format(
                                                                "DD/MM/YY HH:mm"
                                                            )}
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            <h2 className="none-message">
                                There are no admin notes yet
                            </h2>
                        )}
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={2}>
                        {request.hasInvestigation ? (
                            <div className="investigation-tab">
                                {/* //TODO:  */}
                                <h2>
                                    There is an open investigation for this
                                    Drive off for the staff member:{" "}
                                    <span>
                                        {request.investigationData?.name}
                                    </span>{" "}
                                    for the amount{" "}
                                    <span>
                                        £{request.investigationData?.amount}
                                    </span>
                                    .
                                </h2>

                                {request.investigationData?.notes.length > 0 ? (
                                    <section className="investigation-note-container">
                                        <table className="investigation-note-table">
                                            <thead>
                                                <tr>
                                                    <th>user</th>
                                                    <th>Note</th>
                                                    <th>Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {request.investigationData?.notes.map(
                                                    (note: any, i: number) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td>
                                                                    {
                                                                        note.userId
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {" "}
                                                                    {note.note}
                                                                </td>
                                                                <td>
                                                                    {" "}
                                                                    {moment(
                                                                        note.dateAdded
                                                                    ).format(
                                                                        "HH:mm DD/MM/YY"
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        );
                                                    }
                                                )}
                                            </tbody>
                                        </table>
                                    </section>
                                ) : null}
                            </div>
                        ) : (
                            <h2 className="none-message">
                                No open investigation
                            </h2>
                        )}
                    </CustomTabPanel>
                </Box>
            </section>
        </Box>
    );
}
