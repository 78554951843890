import { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Alert } from "@mui/material";
import Loader from "../../loader/Loader";
import { getSites } from "../../../utils/sitesAPI";

export default function SingleProductAffinity() {
    const url = process.env.REACT_APP_API_URL;
    const [sites, setSites] = useState<any[]>([]);
    const [selectedSite, setSelectedSite] = useState<number>(0);
    const [selectedMonth, setSelectedMonth] = useState<string>(
        moment().format("YYYY-MM")
    );
    const [productBarcode, setProductBarcode] = useState<string>("");
    const [foundProduct, setFoundProduct] = useState<any>(null);
    const [disable, setDisable] = useState<boolean>(false);
    const [loader, setLoader] = useState<boolean>(false);
    //Alerts
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [errorSeverity, setErrorSeverity] = useState<any>("");
    const [errorAlert, setErrorAlert] = useState<boolean>(false);

    useEffect(() => {
        const fetchSites = async () => {
            let _sites = await getSites();
            setSites(_sites);
        };
        fetchSites();
    }, []);

    const handleErrorAlert = (
        message: string,
        severity: any,
        duration = 5000
    ) => {
        setErrorMessage(message);
        setErrorSeverity(severity);
        setErrorAlert(true);
        setTimeout(() => {
            setErrorAlert(false);
            setErrorMessage("");
            setErrorSeverity("");
            setDisable(false);
        }, duration);
    };

    const convertMonthToNumber = (month: string): number => {
        return parseInt(moment(month, "YYYY-MM").format("M"));
    };

    const numberValidation = (e: any) => {
        const invalidNumberChars = ["-", "+", "e", "E", "."];
        if (invalidNumberChars.includes(e.key)) {
            e.preventDefault();
        }
    };

    const handleProductLookup = async (): Promise<void> => {
        setLoader(true);
        setDisable(true);
        let _monthNumber = convertMonthToNumber(selectedMonth);

        axios
            .get(
                `${url}//api/single-product-affinity?barcode=${productBarcode}&month=${_monthNumber}&locationId=${selectedSite}`
            )
            .then((res) => {
                let _productInfo = res.data;
                let _affinity_products = [];

                for (const [key, value] of Object.entries(
                    _productInfo.affinity_products
                )) {
                    let _affinityObj = { name: key, freq: value };
                    _affinity_products.push(_affinityObj);
                }

                _productInfo.affinity_products = _affinity_products;
                setFoundProduct(_productInfo);
                handleErrorAlert("Product successfully found", "success");
                setLoader(false);
            })
            .catch((err) => {
                const errorResponse = err.response.data;
                handleErrorAlert(errorResponse.message, "error");
                setFoundProduct(null);
                setLoader(false);
            });
    };

    return (
        <main className="single-product-affinity">
            <section className="search-area">
                <select
                    value={selectedSite}
                    onChange={(e) => setSelectedSite(parseInt(e.target.value))}
                >
                    <option value={0}>All Sites</option>
                    {sites.length > 0
                        ? sites.map((site: any, i: number) => {
                              return (
                                  <option key={i} value={site.epos}>
                                      {site.name}
                                  </option>
                              );
                          })
                        : null}
                </select>
                <input
                    type="number"
                    placeholder="Enter barcode"
                    onKeyDown={(e) => {
                        numberValidation(e);
                    }}
                    min="0"
                    onChange={(e) => setProductBarcode(e.target.value)}
                    value={productBarcode}
                />
                <input
                    type="month"
                    value={selectedMonth}
                    onChange={(e) => {
                        setSelectedMonth(e.target.value);
                    }}
                    max={moment().format("YYYY-MM")}
                />
                <Button
                    variant="contained"
                    color="primary"
                    disabled={disable || !selectedMonth || !productBarcode}
                    onClick={handleProductLookup}
                >
                    <SearchIcon />
                </Button>
                <div className="error-container">
                    {errorAlert ? (
                        <Alert className="error-alert" severity={errorSeverity}>
                            {errorMessage}
                        </Alert>
                    ) : null}
                </div>
            </section>
            <section className="display-table-container">
                {!loader ? (
                    <>
                        {" "}
                        {foundProduct !== null ? (
                            <table className="display-table">
                                <thead>
                                    <tr className="table-header-dets">
                                        <th>{foundProduct?.search_product}</th>
                                        <th>
                                            BARCODE: {foundProduct?.barcode}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {foundProduct?.affinity_products.length >
                                    0 ? (
                                        <>
                                            <tr className="affinity-header">
                                                <th colSpan={2}>
                                                    Affinity Products
                                                </th>
                                            </tr>
                                            {foundProduct.affinity_products.map(
                                                (product: any, i: number) => {
                                                    return (
                                                        <tr>
                                                            <td>
                                                                {product.name}
                                                            </td>
                                                            <td>
                                                                {product.freq *
                                                                    100}
                                                                %
                                                            </td>
                                                        </tr>
                                                    );
                                                }
                                            )}
                                        </>
                                    ) : (
                                        <tr>
                                            <td colSpan={2}>
                                                No Affinity Products
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        ) : null}
                    </>
                ) : (
                    <Loader />
                )}
            </section>
        </main>
    );
}
