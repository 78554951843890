// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.around__sphere {
  margin: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh;
}
.around__sphere .sphere {
  background-image: radial-gradient(circle, #2c53a0, #2c53a0, #3c53a0, #3c53a0, #4353a0);
  height: 15vh;
  width: 15vh;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform-style: preserve-3d;
  box-shadow: inset 0 0 10px 1px rgba(69, 83, 160, 0.54);
}
.around__sphere .sphere .sphere__border {
  position: absolute;
  height: 18vh;
  width: 18vh;
  border-radius: 50%;
  border: 12px solid rgba(100, 196, 224, 0.988);
  animation: spin 10s infinite;
  transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
}

@keyframes spin {
  0% {
    transform: rotateX(0deg) rotateY(20deg) rotateZ(0deg);
  }
  25% {
    transform: rotateX(360deg) rotateY(20deg) rotateZ(90deg);
  }
  50% {
    transform: rotateX(0deg) rotateY(20deg) rotateZ(180deg);
  }
  75% {
    transform: rotateX(-360deg) rotateY(20deg) rotateZ(270deg);
  }
  100% {
    transform: rotateX(0deg) rotateY(0deg) rotateZ(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/loader/loader.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;AACJ;AAAI;EACI,sFAAA;EAQA,YAAA;EACA,WAAA;EACA,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,4BAAA;EACA,sDAAA;AALR;AAMQ;EACI,kBAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;EACA,6CAAA;EACA,4BAAA;EACA,oDAAA;AAJZ;;AAQA;EACI;IACI,qDAAA;EALN;EAOE;IACI,wDAAA;EALN;EAOE;IACI,uDAAA;EALN;EAOE;IACI,0DAAA;EALN;EAOE;IACI,sDAAA;EALN;AACF","sourcesContent":[".around__sphere {\n    margin: auto;\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 40vh;\n    .sphere {\n        background-image: radial-gradient(\n            circle,\n            #2c53a0,\n            #2c53a0,\n            #3c53a0,\n            #3c53a0,\n            #4353a0\n        );\n        height: 15vh;\n        width: 15vh;\n        border-radius: 50%;\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        transform-style: preserve-3d;\n        box-shadow: inset 0 0 10px 1px rgba(69, 83, 160, 0.54);\n        .sphere__border {\n            position: absolute;\n            height: 18vh;\n            width: 18vh;\n            border-radius: 50%;\n            border: 12px solid rgba(100, 196, 224, 0.988);\n            animation: spin 10s infinite;\n            transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);\n        }\n    }\n}\n@keyframes spin {\n    0% {\n        transform: rotateX(0deg) rotateY(20deg) rotateZ(0deg);\n    }\n    25% {\n        transform: rotateX(360deg) rotateY(20deg) rotateZ(90deg);\n    }\n    50% {\n        transform: rotateX(0deg) rotateY(20deg) rotateZ(180deg);\n    }\n    75% {\n        transform: rotateX(-360deg) rotateY(20deg) rotateZ(270deg);\n    }\n    100% {\n        transform: rotateX(0deg) rotateY(0deg) rotateZ(360deg);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
