import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Paper, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import '../styles/FuelReports.scss';
import CarCrashIcon from '@mui/icons-material/CarCrash';

interface Notification {
    fuelTransactionId: number;
    paymentType: string;
    paymentTime: string;
    fuelType?: string;
    fuelQuantity?: number;
    fuelValue?: number;
    siteName?: string;
  }

const Notifications: React.FC = () => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [selectedNotification, setSelectedNotification] = useState<Notification | null>(null);
  const [open, setOpen] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const url = `${process.env.REACT_APP_API_URL}/api/recent-drive-off`  

  // Fetch read status from localStorage
  const getReadStatus = (id: number) => {
    const readStatus = localStorage.getItem(`notification-read-${id}`);
    return readStatus === 'true'; // Returns true if read, false otherwise
  };

  const fetchRecentDriveOff = async () => {
    try {
      const response = await fetch(url);
      const data: Notification[] = await response.json();
      const unreadNotifications = data.filter(n => !getReadStatus(n.fuelTransactionId));
      setNotifications(data);
      setUnreadCount(unreadNotifications.length); 
    } catch (error) {
      console.log('Error fetching recent drive-off:', error);
    }
  };

  // Mark notification as read by storing it in localStorage
  const markAsRead = (fuelTransactionId: number) => {
    localStorage.setItem(`notification-read-${fuelTransactionId}`, 'true');
    setNotifications(prevNotifications =>
      prevNotifications.map(n => n.fuelTransactionId === fuelTransactionId ? { ...n } : n)
    );
    setUnreadCount(prevCount => prevCount - 1);
  };

  const handleClickOpen = (notification: Notification) => {
    setSelectedNotification(notification);
    setOpen(true);
    if (!getReadStatus(notification.fuelTransactionId)) {
      markAsRead(notification.fuelTransactionId);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedNotification(null);
  };

  useEffect(() => {
    fetchRecentDriveOff();
    const interval = setInterval(fetchRecentDriveOff, 600000); // Poll every 10 minutes
    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, []);

  return (
    <Box>
            <Typography gutterBottom className='notifTitle'>
              <div style={{display:"inline-block"}}><div style={{display: 'flex',alignItems: 'center',flexWrap: 'wrap',}}><CarCrashIcon/> <span> &nbsp; Today's Drive Offs ({notifications.length})</span></div></div>
                {unreadCount > 0 && <span className="unreadAlert">({unreadCount} unread)</span>}
            </Typography>
            
            <div className='notification'>
                <div className='notifContent'>
                    {notifications.map(notification => (
                        <Paper key={notification.fuelTransactionId} elevation={3} style={{ padding: '10px', marginBottom: '10px', backgroundColor: getReadStatus(notification.fuelTransactionId) ? '#f5f5f5' : '#e0f7fa' }}>
                        <Typography variant="body1">
                            Drive Off Detected at {new Date(notification.paymentTime).toLocaleString()}
                        </Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleClickOpen(notification)}
                            style={{ marginTop: '10px' }}
                        >
                            View Details
                        </Button>
                        </Paper>
                    ))}
                    {notifications.length === 0 && (
                        <Typography variant="body2" className='none'>
                        No drive offs to show.
                        </Typography>
                    )}
                </div>
            </div>
            <br/>
      {/* Modal for detailed view */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle className='driveOffModal'>Drive Off Details</DialogTitle>
        <DialogContent>
          {selectedNotification && (
            <>
              <Typography variant="body1"><strong>Transaction ID:</strong> {selectedNotification.fuelTransactionId}</Typography>
              <Typography variant="body1"><strong>Payment Time:</strong> {new Date(selectedNotification.paymentTime).toLocaleString()}</Typography>
              <Typography variant="body1"><strong>Fuel Type:</strong> {selectedNotification.fuelType}</Typography>
              <Typography variant="body1"><strong>Quantity:</strong> {selectedNotification.fuelQuantity} liters</Typography>
              <Typography variant="body1"><strong>Price:</strong> £{selectedNotification.fuelValue?.toFixed(2)}</Typography>
              <Typography variant="body1"><strong>Site Name:</strong> {selectedNotification.siteName}</Typography>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
    </Dialog>
    </Box>
  );
};

export default Notifications;