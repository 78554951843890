import axios from "axios";

export const getSites = async () => {
    return await axios
        .get("https://nitrous.rt-itservices.co.uk/sites/data")
        .then((res) => res.data)
        .catch((err) => {
            console.log(err);
            return [];
        });
};
