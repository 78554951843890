// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 768px) {
  .footer {
    margin-left: 0%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/styles/Footer.scss"],"names":[],"mappings":"AAAA;EACI;IACI,eAAA;EACN;AACF","sourcesContent":["@media (max-width: 768px) {\n    .footer{\n        margin-left: 0%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
