import React, { useState, useEffect } from 'react';
import { MenuItem, Select, Button, FormControl, InputLabel, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import csv from '../../../images/csv.png';
import FuelComparisonChart from './FuelComparisonChart';
import '../styles/FuelReports.scss';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import html2canvas from 'html2canvas';
import { fontWeight } from 'html2canvas/dist/types/css/property-descriptors/font-weight';

interface ComparisonData {
  day: string;
  current_quantity: number;
  previous_quantity: number;
  current_value?: number;
  previous_value?: number;
}

type DataType = 'quantity' | 'value';

const FuelPerformance: React.FC = () => {
  const [siteName, setSiteName] = useState('all');
  const [startDate, setStartDate] = useState('last-week');
  const [dataType, setDataType] = useState('quantity');
  const [fuelType, setFuelType] = useState('all');
  const [comparisonData, setComparisonData] = useState<ComparisonData[]>([]);
  const [loading, setLoading] = useState(false);
  const url = `${process.env.REACT_APP_API_URL}/api/fuel-comparison?site_name=${siteName}&start_date=${startDate}&data_type=${dataType}&fuel_type=${fuelType}`;
  
  const totalCurrent = comparisonData.reduce((total, row) => {
    return total + (row.current_quantity || row.current_value || 0);
  }, 0).toFixed(2);

  const totalPrevious = comparisonData.reduce((total, row) => {
    return total + (row.previous_quantity || row.previous_value || 0);
  }, 0).toFixed(2);

  useEffect(() => {
    const fetchData = async () => {
    setLoading(true);
    try {
      const response = await fetch(url)
      const data = await response.json();
      if (dataType === 'quantity') {
        setComparisonData(data.quantity_comparison);
      } else {
        setComparisonData(data.value_comparison);
      }
    } catch (error) {
      console.error('Error fetching fuel comparison data:', error);
    } finally {
      setLoading(false);
    }
    };
    fetchData();
  },[siteName, startDate, dataType, fuelType]);

  const handleExportCSV = () => {
    if (comparisonData.length === 0) {
      alert("No data to export.");
      return;
    }
    if(startDate =='last-day'){
      const csvHeader =["Time", "Current Week", "Previous Week"]
      const csvRows = comparisonData.map(item => [
        item.day,
        item.current_quantity || item.current_value,
        item.previous_quantity || item.previous_value,
      ]);
      let csvContent = `${csvHeader.join(',')}\n`; // Add header row
      csvRows.forEach(row => {
          csvContent += row.join(',') + '\n'; // Add each row of data
    });
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Fuel_quantity|volume_comparison_${new Date().toLocaleDateString()}_Time(${new Date().toLocaleTimeString()}).csv`); // File name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    else{      
      const csvHeader =["Day", "Date", "Current Week", "Previous Week"]
      const csvRows = comparisonData.map(item => [
        item.day,
        item.current_quantity || item.current_value,
        item.previous_quantity || item.previous_value,
      ]);
      let csvContent = `${csvHeader.join(',')}\n`; // Add header row
      csvRows.forEach(row => {
          csvContent += row.join(',') + '\n'; // Add each row of data
      });

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Fuel_quantity|volume_comparison_${new Date().toLocaleDateString()}.csv`); // File name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleExportPDF = async () => {
    const pdf = new jsPDF();
    const title = 'Fuel Performance Comparison';
    pdf.text(title, 14, 20);
    if (comparisonData.length === 0) {
      alert("No data to export.");
      return;
    }

    const chartElement = document.querySelector('.comparisonChart') as HTMLElement;
    const originalBackground = chartElement.style.backgroundColor;
    chartElement.style.backgroundColor = '#fff';
    chartElement.style.boxShadow = 'none';
    chartElement.style.opacity = '1';
    const canvas = await html2canvas(chartElement, {
      scale: 3,
      useCORS: true,
      backgroundColor: '#fff',
    });
    chartElement.style.backgroundColor = originalBackground;
    const imgData = canvas.toDataURL('image/png');
    pdf.text(`[Site Name: ${siteName}] [Date: ${startDate}] [Data Type: ${dataType}] [Fuel Type: ${fuelType}]`, 14, 40);
    pdf.addImage(imgData, 'PNG', 14, 50, 180, 120);
    
    pdf.save(`Fuel_Performance_Comparison_Chart_${new Date().toLocaleDateString()}.pdf`);
  };

  return (
    <div>
      <div className='exportButtons'>
          <IconButton onClick={handleExportCSV} aria-label="Export as CSV">
            <img src={csv} className='csvIcon'/>
          </IconButton>
          <IconButton onClick={handleExportPDF} aria-label="Export as PDF">
            <PictureAsPdfIcon sx={{fontSize:"30px"}}/>
          </IconButton>
        </div>
        
      <h2>Fuel Comparison</h2>

      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <InputLabel>Site Name</InputLabel>
        <Select value={siteName} onChange={(e) => setSiteName(e.target.value)}>
          <MenuItem value="all">All</MenuItem>
          <MenuItem value="autoport">Autoport</MenuItem>
          <MenuItem value="bearsden">Bearsden</MenuItem>
          <MenuItem value="braeside">Braeside</MenuItem>
          <MenuItem value="bridgeton">Bridgeton</MenuItem>
          <MenuItem value="rutherglen">Rutherglen</MenuItem>
        </Select>
      </FormControl>

      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <InputLabel>Start Date</InputLabel>
        <Select value={startDate} onChange={(e) => setStartDate(e.target.value)}>
          <MenuItem value="last-day">Today vs. Yesterday</MenuItem>
          <MenuItem value="last-week">This Week vs. Last Week</MenuItem>
          <MenuItem value="last-month">Last Month vs. Previous Month</MenuItem>
        </Select>
      </FormControl>

      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <InputLabel>Data Type</InputLabel>
        <Select value={dataType} onChange={(e) => setDataType(e.target.value)}>
          <MenuItem value="quantity">Quantity</MenuItem>
          <MenuItem value="value">Value</MenuItem>
        </Select>
      </FormControl>

      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <InputLabel>Fuel Type</InputLabel>
        <Select value={fuelType} onChange={(e) => setFuelType(e.target.value)}>
          <MenuItem value="all">All</MenuItem>
          <MenuItem value="Diesel">Diesel</MenuItem>
          <MenuItem value="Super Diesel">Super Diesel</MenuItem>
          <MenuItem value="Ultimate Diesel">Ultimate Diesel</MenuItem>
          <MenuItem value="Unleaded">Unleaded</MenuItem>
          <MenuItem value="Super Unleaded">Super Unleaded</MenuItem>
          <MenuItem value="Ultimate Unleaded">Ultimate Unleaded</MenuItem>
        </Select>
      </FormControl>
      <div className='comparisonChart'>
        {comparisonData.length > 0 && (
                <FuelComparisonChart
                  labels={comparisonData.map((data) => data.day)}
                  currentData={comparisonData.map((data) => data.current_quantity || data.current_value || 0)}
                  previousData={comparisonData.map((data) => data.previous_quantity || data.previous_value || 0)}
                />
              )}
      </div>
      <div className='comparisonTable'>
        <TableContainer component={Paper}>
          <Table className='tableContent'>
            <Table stickyHeader>
              <TableHead className='tableHead'>
                <TableRow>
                  <TableCell className='tableHead'>Date</TableCell>
                  <TableCell className='tableHead'>Current {dataType.charAt(0).toUpperCase() + dataType.slice(1)}</TableCell>
                  <TableCell className='tableHead'>Previous {dataType.charAt(0).toUpperCase() + dataType.slice(1)}</TableCell>
                </TableRow>
              </TableHead>
                <TableBody>
                  {comparisonData.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{row.day}</TableCell>
                      <TableCell>{row.current_quantity || row.current_value}</TableCell>
                      <TableCell>{row.previous_quantity || row.previous_value}</TableCell>
                    </TableRow>
                  ))}
                  
                  {/* Total Row */}
                  <TableRow>
                    <TableCell className='tableHead'>Total</TableCell>
                    <TableCell className='tableHead'>{totalCurrent}</TableCell>
                    <TableCell className='tableHead'>{totalPrevious}</TableCell>
                  </TableRow>
                </TableBody>
            </Table>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default FuelPerformance;