import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Loader from "../../loader/Loader";

const ReportTable = () => {
  let Url: string = `${process.env.REACT_APP_REFUNDS_URL}/analytics/managers_table`;

  const [data, setData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    console.log('mounted')
    getTableData(Url);
  }, []);

  const getTableData = async (Url: string) => {
    let res: any = await axios
      .get(Url)
      .then((res) => res)
      .catch((err) => console.log(err));
    setData(res?.data);
    setIsLoading(false);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      //   fontWeight:600
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      fontWeight: 600,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  return (
    <div>
      {isLoading ? (
        <div>
          <Loader />
        </div>
      ) : (
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 700, maxHeight: "800px" }}
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell align="right">
                  Avg Discouts/month
                </StyledTableCell>
                <StyledTableCell align="right">
                  Avg Discount Amt/month
                </StyledTableCell>
                <StyledTableCell align="right">
                  Avg Refund Amt/month
                </StyledTableCell>
                <StyledTableCell align="right">
                  Avg Refund/months
                </StyledTableCell>
                <StyledTableCell align="right">Latest Entry</StyledTableCell>
                <StyledTableCell align="right">
                  Num of Discounts
                </StyledTableCell>
                <StyledTableCell align="right">Num of Refunds</StyledTableCell>
                <StyledTableCell align="right">Oldest Entry</StyledTableCell>
                <StyledTableCell align="right">
                  Total Discount Amt
                </StyledTableCell>
                <StyledTableCell align="right">Total months</StyledTableCell>
                <StyledTableCell align="right">
                  Total Refund value
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.map((row: any) => (
                  <StyledTableRow key={row.user_name}>
                    <StyledTableCell component="th" scope="row">
                      {row.user_name.split("@")[0].split(".")[0].toUpperCase()}{" "}
                      {row.user_name.split("@")[0].split(".")[1]?.toUpperCase()}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.avg_discounts_per_month.toFixed(2)}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.avg_discounts_value_per_month.toFixed(2)}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.avg_refund_value_per_month.toFixed(2)}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.avg_refunds_per_month.toFixed(2)}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.latest_entry}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.number_of_discounts}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.number_of_refunds}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.oldest_entry}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.total_discount_value.toFixed(2)}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.total_months}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.total_refund_value.toFixed(2)}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default ReportTable;
