import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    CircularProgress,
    Dialog,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Box,
    Tab,
    Tabs,
    Stack,
    Grid,
    Slide,
    AppBar,
    Toolbar,
    IconButton,
} from "@mui/material";
import {
    PieChart,
    Pie,
    Cell,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from "recharts";
import "../styles/FuelReports.scss";
import { TransitionProps } from "@mui/material/transitions";
import CloseIcon from "@mui/icons-material/Close";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import csv from "../../../images/csv.png";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import SearchIcon from "@mui/icons-material/Search";

const COLORS = [
    "#0088FE",
    "#00C49F",
    "#FFBB28",
    "#FF8042",
    "#8884D8",
    "#82ca9d",
    "82adca",
    "#ca82be",
];

interface StationData {
    siteName: string;
    totalQuantity: number;
    totalValue: number;
}

interface SiteMetrics {
    fuel_quantity_by_type: Array<{
        fuelType: string;
        totalFuelQuantity: number;
    }>;
    transaction_count_by_site: {
        totalTransactions: number;
    };
    avg_fuel_quantity_by_site: {
        avgFuelQuantity: number;
    };
    total_fuel_value_by_payment: Array<{
        paymentType: string;
        totalFuelValue: number;
    }>;
    transaction_count_by_payment: Array<{
        paymentType: string;
        transactionCount: number;
    }>;
    avg_fuel_value_by_site: {
        avgFuelValue: number;
    };
    fuel_quantity_by_payment: Array<{
        paymentType: string;
        totalFuelQuantity: number;
    }>;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const FuelStations: React.FC = () => {
    const [value, setValue] = React.useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    const [stationsData, setStationsData] = useState<StationData[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [open, setOpen] = useState<boolean>(false);
    const [selectedSite, setSelectedSite] = useState<string | null>(null);
    const [selectedSiteName, setSelectedSiteName] = useState("");
    const [siteMetrics, setSiteMetrics] = useState<SiteMetrics | null>(null);
    const [modalLoading, setModalLoading] = useState<boolean>(false);
    const url = `${process.env.REACT_APP_API_URL}/api/fuel-stations`;
    const [dateRangeStart, setDateRangeStart] = useState<string>(
        moment().format("YYYY-MM-DD")
    );
    const [dateRangeEnd, setDateRangeEnd] = useState<string>(
        moment().format("YYYY-MM-DD")
    );

    useEffect(() => {
        const fetchStationsData = async () => {
            setLoading(true);
            try {
                let response = await axios.get<StationData[]>(url);
                let braeIndex = response.data.findIndex(
                    (store) => store.siteName === "braeside"
                );
                if (braeIndex < 0) {
                    response.data.push({
                        siteName: "braeside",
                        totalQuantity: 0,
                        totalValue: 0,
                    });
                }
                console.log(response.data);
                setStationsData(response.data);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching the stations data", error);
                setLoading(false);
            }
        };
        fetchStationsData();
        // const interval = setInterval(() => {
        //     fetchStationsData();
        // }, 180000);

        // return () => clearInterval(interval);
    }, []);

    //This will run when date is changed at the Site Level

    const handleClickOpen = async (siteName: string) => {
        setSelectedSite(siteName);
        setModalLoading(true);
        setOpen(true);

        try {
            const response = await axios.get<SiteMetrics>(
                `${process.env.REACT_APP_API_URL}/api/site-metrics?siteName=${siteName}&startDate=${dateRangeStart} 0:0:0&endDate=${dateRangeEnd} 23:59:59`
            );
            console.log(response.data);
            setSiteMetrics(response.data);
        } catch (error) {
            console.error(`Error fetching site metrics for ${siteName}`, error);
        } finally {
            setModalLoading(false);
        }
    };
    const handleClose = () => {
        setOpen(false);
        setSelectedSite(null);
        setSiteMetrics(null);
        setDateRangeStart(moment().format("YYYY-MM-DD"));
        setDateRangeEnd(moment().format("YYYY-MM-DD"));
    };

    const pieChartData =
        siteMetrics?.fuel_quantity_by_type?.map((item) => ({
            name: item.fuelType,
            value: item.totalFuelQuantity,
        })) || [];
    const pieChartData2 =
        siteMetrics?.transaction_count_by_payment?.map((item) => ({
            name: item.paymentType,
            value: item.transactionCount,
        })) || [];

    const handleExportCSV = () => {
        if (stationsData.length === 0) {
            alert("No data to export.");
            return;
        }
        const csvHeader = [
            "Site Name",
            "Fuel Total Volume",
            "Fuel Total Value",
        ];
        const csvRows = stationsData.map((item) => [
            item.siteName,
            item.totalQuantity,
            item.totalValue,
        ]);

        let csvContent = `${csvHeader.join(",")}\n`;
        csvRows.forEach((row) => {
            csvContent += row.join(",") + "\n";
        });

        const blob = new Blob([csvContent], {
            type: "text/csv;charset=utf-8;",
        });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
            "download",
            `Fuel_Stations_Live_Data_${new Date().toLocaleDateString()}_Time(${new Date().toLocaleTimeString()}).csv`
        ); // File name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleExportPDF = () => {
        const pdf = new jsPDF();
        const title = "Fuel Stations Data";
        pdf.text(title, 14, 20);
        pdf.text(
            `[${new Date().toLocaleDateString()}] (${new Date().toLocaleTimeString()})`,
            14,
            30
        );

        const headers = ["Site Name", "Fuel Total Volume", "Fuel Total Value"];

        const rows = stationsData.map((item) => [
            item.siteName,
            `${item.totalQuantity} L`,
            new Intl.NumberFormat("en-GB", {
                style: "currency",
                currency: "GBP",
            }).format(item.totalValue),
        ]);

        autoTable(pdf, {
            head: [headers],
            body: rows,
            startY: 40,
        });
        pdf.save(
            `Fuel_Stations_Live_Data_Data_${new Date().toLocaleDateString()}_Time(${new Date().toLocaleTimeString()}).pdf`
        );
    };

    const handleDateRangeSearch = async () => {
        try {
            const response = await axios.get<SiteMetrics>(
                `${process.env.REACT_APP_API_URL}/api/site-metrics?siteName=${selectedSite}&startDate=${dateRangeStart} 0:0:0&endDate=${dateRangeEnd} 23:59:59`
            );
            console.log(response.data);
            setSiteMetrics(response.data);
        } catch (error) {
            console.error(
                `Error fetching site metrics for ${selectedSite}`,
                error
            );
        } finally {
            setModalLoading(false);
        }
    };

    return (
        <>
            <div className="exportButtonStations">
                <IconButton
                    onClick={handleExportCSV}
                    aria-label="Export as CSV"
                >
                    <img src={csv} className="csvIcon" />
                </IconButton>
                <IconButton
                    onClick={handleExportPDF}
                    aria-label="Export as PDF"
                >
                    <PictureAsPdfIcon sx={{ fontSize: "30px" }} />
                </IconButton>
            </div>
            <div>
                <Typography variant="h5" gutterBottom>
                    Fuel Stations - Today's Live Data
                </Typography>

                {loading ? (
                    <CircularProgress />
                ) : (
                    <div className="stationsTable">
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="stationsHead">
                                            Site Name
                                        </TableCell>
                                        <TableCell className="stationsHead">
                                            Total Volume (L)
                                        </TableCell>
                                        <TableCell className="stationsHead">
                                            Total Value (£)
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody className="tableRow">
                                    {stationsData.map((station, index) => (
                                        <TableRow
                                            key={index}
                                            onClick={() => {
                                                handleClickOpen(
                                                    station.siteName
                                                );
                                                setSelectedSiteName(
                                                    station.siteName
                                                );
                                            }}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <TableCell className="stationsSite">
                                                {station.siteName.toUpperCase()}
                                            </TableCell>
                                            <TableCell>
                                                {station.totalQuantity}
                                            </TableCell>
                                            <TableCell>
                                                {new Intl.NumberFormat(
                                                    "en-GB",
                                                    {
                                                        style: "currency",
                                                        currency: "GBP",
                                                    }
                                                ).format(station.totalValue)}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Dialog
                            fullScreen
                            open={open}
                            onClose={handleClose}
                            TransitionComponent={Transition}
                        >
                            <AppBar
                                sx={{ position: "relative" }}
                                className="titleBar"
                            >
                                <Toolbar className="titleBar">
                                    <Typography
                                        sx={{ ml: 2, flex: 1 }}
                                        variant="h5"
                                        component="div"
                                    >
                                        {selectedSite?.toUpperCase()}
                                    </Typography>
                                    <IconButton
                                        edge="end"
                                        color="inherit"
                                        onClick={handleClose}
                                        aria-label="close"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Toolbar>
                            </AppBar>

                            <DialogContent className="dialogContent">
                                <div className="siteDetails">
                                    <Box
                                        sx={{
                                            borderBottom: 1,
                                            borderColor: "divider",
                                        }}
                                        className="tab"
                                    >
                                        <Tabs
                                            value={value}
                                            onChange={handleChange}
                                            aria-label="basic tabs example"
                                        >
                                            <Tab
                                                label="Fuel Volume Data"
                                                sx={{ fontSize: "15px" }}
                                                {...a11yProps(0)}
                                            />
                                            <Tab
                                                label="Fuel Value Data"
                                                sx={{ fontSize: "15px" }}
                                                {...a11yProps(1)}
                                            />
                                        </Tabs>
                                    </Box>

                                    <CustomTabPanel value={value} index={0}>
                                        <Grid container>
                                            <Grid item xs={12} md={4}>
                                                <div className="typeList">
                                                    {modalLoading ? (
                                                        <CircularProgress />
                                                    ) : (
                                                        siteMetrics && (
                                                            <>
                                                                <div className="date-search">
                                                                    <input
                                                                        type="date"
                                                                        placeholder="Search Date"
                                                                        className="date-input"
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            setDateRangeStart(
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            );
                                                                        }}
                                                                        value={
                                                                            dateRangeStart
                                                                        }
                                                                        max={
                                                                            dateRangeEnd
                                                                        }
                                                                    />
                                                                    <span>
                                                                        -
                                                                    </span>
                                                                    <input
                                                                        type="date"
                                                                        placeholder="Search Date"
                                                                        className="date-input"
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            setDateRangeEnd(
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            );
                                                                        }}
                                                                        value={
                                                                            dateRangeEnd
                                                                        }
                                                                        min={
                                                                            dateRangeStart
                                                                        }
                                                                    />
                                                                    <Button
                                                                        variant="contained"
                                                                        disabled={
                                                                            dateRangeEnd ===
                                                                                "" ||
                                                                            dateRangeStart ===
                                                                                ""
                                                                        }
                                                                        onClick={handleDateRangeSearch}
                                                                    >
                                                                        <SearchIcon />
                                                                    </Button>
                                                                </div>
                                                                <div>
                                                                    {siteMetrics.fuel_quantity_by_type.map(
                                                                        (
                                                                            data,
                                                                            index
                                                                        ) => (
                                                                            <div
                                                                                key={
                                                                                    index
                                                                                }
                                                                                className="fuelVolumes"
                                                                            >
                                                                                <p>
                                                                                    <p>
                                                                                        {
                                                                                            data.fuelType
                                                                                        }

                                                                                        :
                                                                                    </p>{" "}
                                                                                    {
                                                                                        data.totalFuelQuantity
                                                                                    }{" "}
                                                                                    L
                                                                                </p>
                                                                            </div>
                                                                        )
                                                                    )}
                                                                </div>
                                                            </>
                                                        )
                                                    )}
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={8}>
                                                <div className="pieChart">
                                                    {pieChartData.length >
                                                        0 && (
                                                        <ResponsiveContainer
                                                            width="100%"
                                                            height={350}
                                                        >
                                                            <PieChart>
                                                                <Pie
                                                                    data={
                                                                        pieChartData
                                                                    }
                                                                    dataKey="value"
                                                                    nameKey="name"
                                                                    cx="50%"
                                                                    cy="50%"
                                                                    outerRadius={
                                                                        120
                                                                    }
                                                                    label
                                                                >
                                                                    {pieChartData.map(
                                                                        (
                                                                            entry,
                                                                            index
                                                                        ) => (
                                                                            <Cell
                                                                                key={`cell-${index}`}
                                                                                fill={
                                                                                    COLORS[
                                                                                        index %
                                                                                            COLORS.length
                                                                                    ]
                                                                                }
                                                                            />
                                                                        )
                                                                    )}
                                                                </Pie>
                                                                <Tooltip />
                                                                <Legend />
                                                            </PieChart>
                                                        </ResponsiveContainer>
                                                    )}
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </CustomTabPanel>

                                    <CustomTabPanel value={value} index={1}>
                                        <Grid container>
                                            <Grid item xs={12} md={3}>
                                                <div className="fuelValues">
                                                    {siteMetrics?.total_fuel_value_by_payment.map(
                                                        (data, index) => (
                                                            <div
                                                                key={index}
                                                                className="fuelPayments"
                                                            >
                                                                <p>
                                                                    <p>
                                                                        {
                                                                            data.paymentType
                                                                        }
                                                                        :
                                                                    </p>{" "}
                                                                    {new Intl.NumberFormat(
                                                                        "en-GB",
                                                                        {
                                                                            style: "currency",
                                                                            currency:
                                                                                "GBP",
                                                                        }
                                                                    ).format(
                                                                        data.totalFuelValue
                                                                    )}
                                                                </p>
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                <div className="fuelValues">
                                                    {siteMetrics?.fuel_quantity_by_payment.map(
                                                        (data, index) => (
                                                            <div
                                                                key={index}
                                                                className="fuelVolumesPayment"
                                                            >
                                                                <p>
                                                                    {
                                                                        data.totalFuelQuantity
                                                                    }{" "}
                                                                    Liters
                                                                </p>
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <div className="pieChart">
                                                    <p>
                                                        Number Of Transactions
                                                        For Each Payment Type:
                                                    </p>
                                                    {pieChartData.length >
                                                        0 && (
                                                        <ResponsiveContainer
                                                            width="100%"
                                                            height={350}
                                                        >
                                                            <PieChart>
                                                                <Pie
                                                                    data={
                                                                        pieChartData2
                                                                    }
                                                                    dataKey="value"
                                                                    nameKey="name"
                                                                    cx="50%"
                                                                    cy="50%"
                                                                    outerRadius={
                                                                        120
                                                                    }
                                                                    fill="#ca82be"
                                                                    label
                                                                >
                                                                    {pieChartData.map(
                                                                        (
                                                                            entry,
                                                                            index
                                                                        ) => (
                                                                            <Cell
                                                                                key={`cell-${index}`}
                                                                                fill={
                                                                                    COLORS[
                                                                                        index %
                                                                                            COLORS.length
                                                                                    ]
                                                                                }
                                                                            />
                                                                        )
                                                                    )}
                                                                </Pie>
                                                                <Tooltip />
                                                                <Legend />
                                                            </PieChart>
                                                        </ResponsiveContainer>
                                                    )}
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </CustomTabPanel>
                                </div>
                                <div className="additionalInfo">
                                    <div className="addTitle">
                                        <p>ADDITIONAL INFORMATION</p>
                                    </div>
                                    <div className="additionalContent">
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={4}>
                                                <div className="add">
                                                    <p>
                                                        <p>
                                                            Average Fuel
                                                            Quantity:
                                                        </p>{" "}
                                                        {siteMetrics
                                                            ?.avg_fuel_quantity_by_site
                                                            ?.avgFuelQuantity
                                                            ? `${Number(
                                                                  siteMetrics
                                                                      .avg_fuel_quantity_by_site
                                                                      .avgFuelQuantity
                                                              ).toFixed(2)} L`
                                                            : "N/A"}
                                                    </p>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <div className="add">
                                                    <p>
                                                        <p>
                                                            Average Fuel Value:
                                                        </p>{" "}
                                                        £
                                                        {siteMetrics
                                                            ?.avg_fuel_value_by_site
                                                            ?.avgFuelValue
                                                            ? `${Number(
                                                                  siteMetrics
                                                                      ?.avg_fuel_value_by_site
                                                                      ?.avgFuelValue
                                                              ).toFixed(2)}`
                                                            : "N/A"}
                                                    </p>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <div className="add">
                                                    <p>
                                                        <p>
                                                            Total Transactions:
                                                        </p>{" "}
                                                        {siteMetrics
                                                            ?.transaction_count_by_site
                                                            ?.totalTransactions
                                                            ? `${Number(
                                                                  siteMetrics
                                                                      ?.transaction_count_by_site
                                                                      ?.totalTransactions
                                                              )}`
                                                            : "N/A"}
                                                    </p>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                                <div className="lastDelivery">
                                    <div className="deliveryTitle">
                                        <p>LAST DELIVERY DATE DETECTED</p>
                                    </div>
                                    <p>N/A</p>
                                </div>
                            </DialogContent>
                        </Dialog>
                    </div>
                )}
            </div>
        </>
    );
};

export default FuelStations;
