import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './styles/Sidebar.scss';
import logo from './images/nitropos-logo.png';
import Notifications from './Dashboard/FuelReportPage/FuelDashboard/Notifications';
import HistoryIcon from '@mui/icons-material/History';
import StoreIcon from '@mui/icons-material/Store';
import CategoryIcon from '@mui/icons-material/Category';
import AssignmentReturnedIcon from '@mui/icons-material/AssignmentReturned';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

const Sidebar: React.FC = () => {
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };
    return (
        <div className="sidebar-container">
            {/* Top bar with logo and hamburger menu for mobile */}
            <div className="top-bar">
            <Link to='/'><img src={logo} alt="NitroPos Logo" className="logo" /></Link>
                <MenuIcon onClick={toggleMenu} className="menu-icon" />
            </div>

            {/* Sidebar menu */}
            <div className={`sidebar ${isOpen ? 'open' : ''}`}>
                <ul>
                    <li>
                        <Link to='/sales-history' className={location.pathname === '/sales-history' ? 'active' : ''}>
                            <div style={{ display: "flex", alignItems: 'center' }}>
                                <HistoryIcon />
                                <span>Sales History</span>
                            </div>
                        </Link>
                    </li>

                    <li>
                        <Link to="/stores-perform" className={location.pathname === '/stores-perform' ? 'active' : ''}>
                            <div style={{ display: "flex", alignItems: 'center' }}>
                                <StoreIcon />
                                <span>Stores Performance</span>
                            </div>
                        </Link>
                    </li>

                    <li>
                        <Link to="/products-perform" className={location.pathname === '/products-perform' ? 'active' : ''}>
                            <div style={{ display: "flex", alignItems: 'center' }}>
                                <CategoryIcon />
                                <span>Products Performance</span>
                            </div>
                        </Link>
                    </li>

                    <li>
                        <Link to="/products-turnover" className={location.pathname === '/products-turnover' ? 'active' : ''}>
                            <div style={{ display: "flex", alignItems: 'center' }}>
                                <AssignmentReturnedIcon />
                                <span>Products Turnover</span>
                            </div>
                        </Link>
                    </li>

                    <li>
                        <Link to="/fuel-reports" className={location.pathname === '/fuel-reports' ? 'active' : ''}>
                            <div style={{ display: "flex", alignItems: 'center' }}>
                                <LocalGasStationIcon />
                                <span>Fuel Reports</span>
                            </div>
                        </Link>
                    </li>
                    <li style={{backgroundColor: 'red', color:'white', padding: '0.2em'}}>
                        <Link to="/store-monitoring" className={location.pathname === '/store-monitoring' ? 'active' : ''}>
                            <div style={{ display: "flex", alignItems: 'center', justifyContent:'center' , textAlign: 'center', color: 'white', fontSize: 24, fontWeight: 'light'}}>
                                STORE <br></br>
                                MONITERING
                                <span></span>
                            </div>
                        </Link>
                    </li>

                    <li className="notifBox">
                        <Notifications />
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Sidebar;