import * as React from "react";
import { useEffect, useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Select,
    Tabs,
    Tab,
    Button,
    MenuItem,
    CircularProgress,
    Box,
    Grid,
    Paper,
    TextField,
    IconButton,
} from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import csv from "../../images/csv.png";
import { Autocomplete } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Sidebar from "../../Sidebar";
import Footer from "../../Footer";
import "./products-performance.scss";
import ProductAffinity from "./ProductsAffinity";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import html2canvas from "html2canvas";
import SingleProductAffinity from "./SingleProductAffinity";

interface ProductsData {
    rank: number;
    product_id: number;
    product_name: string;
    quantity_sold: number;
    total_sales: number;
    profit_margin: number;
}
interface ApiResponse {
    total: number;
    pages: number;
    current_page: number;
    data: ProductsData[];
}
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}
function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const ProductsPerform: React.FC = () => {
    const [value, setValue] = React.useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    const [productsData, setProductsData] = useState<ProductsData[]>([]);
    const [period, setPeriod] = useState<string>("all-time");
    const [search, setSearch] = useState<string>("");
    const [site, setSite] = useState<string>("all");
    const [page, setPage] = useState<number>(1);
    const [sortColumn, setSortColumn] = useState<string>("total_sales");
    const [sortDirection, setSortDirection] = useState<string>("desc");
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const url = `${process.env.REACT_APP_API_URL}/api/products-performance?period=${period}&search=${search}&site=${site}&page=${page}&sort=${sortColumn}&direction=${sortDirection}`;

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await fetch(url);
            const result: ApiResponse = await response.json();
            setProductsData(result.data);
        } catch (error) {
            if (error instanceof Error) {
                setError(error.message);
            }
        } finally {
            setLoading(false);
        }
    };
    const handleSearch = () => {
        setPage(1); // Reset to the first page when searching
        fetchData(); // Trigger fetch when the search icon is clicked
    };

    const handleCloseSearch = () => {
        setSearch(""); // Clear search input
        setProductsData([]); // Clear search results
        fetchData(); // Reload original data
    };
    useEffect(() => {
        fetchData();
    }, [period, search, site, page, sortColumn, sortDirection]);

    const handleSort = (column: string) => {
        const isAsc = sortColumn === column && sortDirection === "asc";
        setSortDirection(isAsc ? "desc" : "asc");
        setSortColumn(column);
    };

    const handleExportCSV = () => {
        if (productsData.length === 0) {
            alert("No data to export.");
            return;
        }
        const csvHeader = [
            "Product ID",
            "Product Name",
            "Total Sales",
            "Quantity Sold",
            "Profit Margin",
        ];
        const csvRows = productsData.map((item) => [
            item.product_id,
            item.product_name,
            item.total_sales,
            item.quantity_sold,
            item.profit_margin,
        ]);

        // Create CSV content
        let csvContent = `${csvHeader.join(",")}\n`; // Add header row
        csvRows.forEach((row) => {
            csvContent += row.join(",") + "\n"; // Add each row of data
        });

        // Create a blob and download the CSV file
        const blob = new Blob([csvContent], {
            type: "text/csv;charset=utf-8;",
        });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
            "download",
            `Products_data_${new Date().toLocaleDateString()}.csv`
        ); // File name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Clean up
    };

    const handleExportPDF = async () => {
        const pdf = new jsPDF();
        const title = "Products Performance Report";
        pdf.text(title, 14, 20);

        const headers = [
            "Rank",
            "Product ID",
            "Product Name",
            "Total Salse",
            "Quantity Sold",
            "Profit Margin",
        ];

        const rows = productsData.map((item) => [
            item.rank,
            item.product_id,
            item.product_name,
            new Intl.NumberFormat("en-GB", {
                style: "currency",
                currency: "GBP",
            }).format(item.total_sales),
            item.quantity_sold,
            new Intl.NumberFormat("en-GB", {
                style: "currency",
                currency: "GBP",
            }).format(item.profit_margin),
        ]);

        autoTable(pdf, {
            head: [headers],
            body: rows,
            startY: 30,
        });

        pdf.save(`Products_Performance_${new Date().toLocaleDateString()}.pdf`);
    };
    const handleExportPDFaffinity = async () => {
        const pdf = new jsPDF();
        const title = "Products Affinity Report";
        pdf.text(title, 14, 20);

        const chartElement = document.querySelector(
            ".affinityTable"
        ) as HTMLElement;
        const originalBackground = chartElement.style.backgroundColor;
        chartElement.style.backgroundColor = "#fff";
        chartElement.style.boxShadow = "none";
        chartElement.style.opacity = "1";

        const canvas = await html2canvas(chartElement, {
            scale: 3, // Increases the resolution
            useCORS: true,
            backgroundColor: "#fff", // Ensures cross-origin images are included
        });
        chartElement.style.backgroundColor = originalBackground;
        const imgData = canvas.toDataURL("image/png");
        pdf.addImage(imgData, "PNG", 1, 35, 220, 90);
        pdf.save(`Products_Affinity_${new Date().toLocaleDateString()}.pdf`);
    };

    return (
        <div className="ProductsContent">
            <Grid container>
                <Grid item xs={12} md={2}>
                    <div>
                        <Sidebar></Sidebar>
                    </div>
                </Grid>
                <Grid item xs={12} md={10}>
                    <div className="contentPP">
                        <div className="pageHeader">
                            <h1>Products Performance</h1>
                        </div>
                        <div className="pageContent">
                            <Box
                                sx={{ borderBottom: 1, borderColor: "divider" }}
                            >
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    aria-label="basic tabs example"
                                >
                                    <Tab
                                        label="All Products"
                                        {...a11yProps(0)}
                                    />
                                    <Tab
                                        label="Products Affinity"
                                        {...a11yProps(1)}
                                    />
                                    <Tab
                                        label="Single Product Affinity"
                                        {...a11yProps(1)}
                                    />
                                </Tabs>
                            </Box>
                            <CustomTabPanel value={value} index={0}>
                                <div className="allProductsTab">
                                    <div className="exportButtons">
                                        <IconButton
                                            onClick={handleExportCSV}
                                            aria-label="Export as CSV"
                                        >
                                            <img
                                                src={csv}
                                                className="csvIcon"
                                            />
                                        </IconButton>
                                        <IconButton
                                            onClick={handleExportPDF}
                                            aria-label="Export as PDF"
                                        >
                                            <PictureAsPdfIcon
                                                sx={{ fontSize: "30px" }}
                                            />
                                        </IconButton>
                                    </div>
                                    <br />
                                    <br />
                                    <Grid container>
                                        <div className="choices">
                                            <Grid item xs={12} md={2}>
                                                <div className="periodChoice">
                                                    <p>Period Type:</p>
                                                    <Select
                                                        value={period}
                                                        onChange={(e) =>
                                                            setPeriod(
                                                                e.target.value
                                                            )
                                                        }
                                                    >
                                                        <MenuItem value="all-time">
                                                            All Time
                                                        </MenuItem>
                                                        <MenuItem value="yesterday">
                                                            Yesterday
                                                        </MenuItem>
                                                        <MenuItem value="last-week">
                                                            Last Week
                                                        </MenuItem>
                                                        <MenuItem value="last-month">
                                                            Last Month
                                                        </MenuItem>
                                                    </Select>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <div className="siteChoice">
                                                    <p>Filter by Site:</p>
                                                    <Select
                                                        value={site}
                                                        onChange={(e) =>
                                                            setSite(
                                                                e.target.value
                                                            )
                                                        }
                                                    >
                                                        <MenuItem value="all">
                                                            ALL
                                                        </MenuItem>
                                                        <MenuItem value="19191">
                                                            Autoport
                                                        </MenuItem>
                                                        <MenuItem value="24469">
                                                            Dumbarton Rd
                                                        </MenuItem>
                                                        <MenuItem value="24580">
                                                            Strathclyde
                                                        </MenuItem>
                                                        <MenuItem value="24796">
                                                            Rutherglen
                                                        </MenuItem>
                                                        <MenuItem value="24897">
                                                            Sauchiehall
                                                        </MenuItem>
                                                        <MenuItem value="24923">
                                                            Braeside
                                                        </MenuItem>
                                                        <MenuItem value="24974">
                                                            Coatbridge
                                                        </MenuItem>
                                                        <MenuItem value="24753">
                                                            Hillington
                                                        </MenuItem>
                                                        <MenuItem value="24701">
                                                            Bearsden
                                                        </MenuItem>
                                                        <MenuItem value="24922">
                                                            Bridgeton
                                                        </MenuItem>
                                                        <MenuItem value="25251">
                                                            Wishaw
                                                        </MenuItem>
                                                        <MenuItem value="24195">
                                                            Head Office
                                                        </MenuItem>
                                                    </Select>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={8}>
                                                <div className="searchChoice">
                                                    <Autocomplete
                                                        freeSolo
                                                        options={productsData.map(
                                                            (item) =>
                                                                item.product_name
                                                        )}
                                                        renderInput={(
                                                            params
                                                        ) => (
                                                            <TextField
                                                                {...params}
                                                                label="Search Product By Name or ID"
                                                                variant="outlined"
                                                                value={search}
                                                                onChange={(e) =>
                                                                    setSearch(
                                                                        e.target
                                                                            .value
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </Grid>
                                        </div>
                                    </Grid>
                                    <div className="searchButtons">
                                        <Button
                                            onClick={handleSearch}
                                            style={{ display: "inline" }}
                                        >
                                            <p>Search</p>
                                        </Button>
                                        {search && (
                                            <Button
                                                onClick={handleCloseSearch}
                                                style={{ display: "inline" }}
                                            >
                                                <CloseIcon />
                                            </Button>
                                        )}
                                    </div>
                                    <div className="table">
                                        <Table className="tableContent">
                                            <Table stickyHeader>
                                                <TableHead className="tableHead">
                                                    <TableRow className="tableHead">
                                                        <TableCell
                                                            className="tableHeadRank"
                                                            onClick={() =>
                                                                handleSort(
                                                                    "rank"
                                                                )
                                                            }
                                                        >
                                                            Rank{" "}
                                                            {sortColumn ===
                                                            "rank" ? (
                                                                sortDirection ===
                                                                "asc" ? (
                                                                    <KeyboardArrowUpIcon className="icon" />
                                                                ) : (
                                                                    <KeyboardArrowDownIcon className="icon" />
                                                                )
                                                            ) : (
                                                                <KeyboardArrowDownIcon className="icon" />
                                                            )}
                                                        </TableCell>
                                                        <TableCell className="tableHeadProductId">
                                                            Product ID
                                                        </TableCell>
                                                        <TableCell className="tableHead">
                                                            Product Name
                                                        </TableCell>
                                                        <TableCell className="tableHead">
                                                            Total Sales
                                                        </TableCell>
                                                        <TableCell className="tableHeadQuantitySold">
                                                            Quantity Sold
                                                        </TableCell>
                                                        <TableCell
                                                            className="tableHeadProfit"
                                                            onClick={() =>
                                                                handleSort(
                                                                    "profit_margin"
                                                                )
                                                            }
                                                        >
                                                            Profit Margin{" "}
                                                            {sortColumn ===
                                                            "profit_margin" ? (
                                                                sortDirection ===
                                                                "asc" ? (
                                                                    <KeyboardArrowUpIcon className="icon" />
                                                                ) : (
                                                                    <KeyboardArrowDownIcon className="icon" />
                                                                )
                                                            ) : (
                                                                <KeyboardArrowDownIcon className="icon" />
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                {loading ? (
                                                    <div className="loading">
                                                        <CircularProgress />
                                                    </div>
                                                ) : (
                                                    <TableBody>
                                                        {productsData.map(
                                                            (item) => (
                                                                <TableRow
                                                                    key={
                                                                        item.rank
                                                                    }
                                                                >
                                                                    <TableCell className="RankColumn">
                                                                        {
                                                                            item.rank
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell className="productIdColumn">
                                                                        {
                                                                            item.product_id
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell className="productNameColumn">
                                                                        {
                                                                            item.product_name
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell className="salesColumn">
                                                                        {new Intl.NumberFormat(
                                                                            "en-GB",
                                                                            {
                                                                                style: "currency",
                                                                                currency:
                                                                                    "GBP",
                                                                            }
                                                                        ).format(
                                                                            item.total_sales
                                                                        )}
                                                                    </TableCell>
                                                                    <TableCell className="quantityColumn">
                                                                        {
                                                                            item.quantity_sold
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell className="profitMarginColumn">
                                                                        {new Intl.NumberFormat(
                                                                            "en-GB",
                                                                            {
                                                                                style: "currency",
                                                                                currency:
                                                                                    "GBP",
                                                                            }
                                                                        ).format(
                                                                            item.profit_margin
                                                                        )}
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        )}
                                                    </TableBody>
                                                )}
                                            </Table>
                                        </Table>
                                    </div>
                                    <div className="pageButtons">
                                        <Button
                                            onClick={() => setPage(page - 1)}
                                            disabled={page === 1}
                                        >
                                            Previous
                                        </Button>
                                        <Button
                                            onClick={() => setPage(page + 1)}
                                        >
                                            Next
                                        </Button>
                                    </div>
                                </div>
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={1}>
                                <div className="exportButtons">
                                    <IconButton
                                        onClick={handleExportPDFaffinity}
                                        aria-label="Export as PDF"
                                    >
                                        <PictureAsPdfIcon
                                            sx={{ fontSize: "30px" }}
                                        />
                                    </IconButton>
                                </div>
                                <div className="affinityTable">
                                    <ProductAffinity></ProductAffinity>
                                </div>
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={2}>
                                <SingleProductAffinity />
                            </CustomTabPanel>
                        </div>
                    </div>
                </Grid>
            </Grid>
            <Footer></Footer>
        </div>
    );
};
export default ProductsPerform;
