import React, { useEffect, useRef } from 'react';
import '../styles/FuelReports.scss';
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LineController,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
  ChartData,
} from 'chart.js';

ChartJS.register(
  LineElement,
  PointElement,
  LineController,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend
);


interface FuelComparisonChartProps {
  labels: string[]; // X-axis labels (days or hours)
  currentData: number[]; // Data points for the current period
  previousData: number[]; // Data points for the previous period
}

const FuelComparisonChart: React.FC<FuelComparisonChartProps> = ({ labels, currentData, previousData }) => {
  const chartRef = useRef<ChartJS<'line'> | null>(null);

  const data: ChartData<'line'> = {
    labels,
    datasets: [
      {
        label: 'Current Period', // Label for current period dataset
        data: currentData,
        borderColor: 'rgba(75, 192, 192, 1)', // Line color for current data
        backgroundColor: 'rgba(75, 192, 192, 0.2)', // Background fill for current data
        borderWidth: 2,
        fill: true,
      },
      {
        label: 'Previous Period', // Label for previous period dataset
        data: previousData,
        borderColor: 'rgba(255, 99, 132, 1)', // Line color for previous data
        backgroundColor: 'rgba(255, 99, 132, 0.2)', // Background fill for previous data
        borderWidth: 2,
        fill: true,
      },
    ],
  };

  const options: ChartOptions<'line'> = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'Fuel Quantity Comparison (Current vs. Previous)',
        font: {
        size: window.innerWidth < 600 ? 14 : 18,
        },
      },
      tooltip: {
        mode: 'index',
        intersect: false,
      },
      legend: {
        display: true,
        position: 'top',
        labels: {
          font: {
            size: window.innerWidth < 600 ? 10 : 12, // Adjust font size for legend on small screens
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Date/Time',
          font: {
            size: window.innerWidth < 600 ? 10 : 14, // Adjust font size for small screens
          },
        },
        ticks: {
          font: {
            size: window.innerWidth < 600 ? 8 : 12, // Adjust font size for X-axis ticks
          },
        },
      },
      y: {
        title: {
          display: true,
          text: 'Fuel Quantity',
          font: {
            size: window.innerWidth < 600 ? 10 : 14, // Adjust font size for Y-axis title on small screens
          },
        },
        ticks: {
          font: {
            size: window.innerWidth < 600 ? 8 : 12, // Adjust font size for Y-axis ticks
          },
        },
      },
    },
    layout: {
      padding: {
        left: window.innerWidth < 600 ? 5 : 10, // Adjust padding for smaller screens
        right: window.innerWidth < 600 ? 5 : 10,
        top: window.innerWidth < 600 ? 5 : 10,
        bottom: window.innerWidth < 600 ? 5 : 10,
      },
    },
  };

  useEffect(() => {
    // Destroy the previous chart instance if it exists
    if (chartRef.current) {
      chartRef.current.destroy();
    }

    // Re-create the chart instance using the updated data and options
    const canvas = document.getElementById('fuel-comparison-chart') as HTMLCanvasElement;
    if (canvas) {
      chartRef.current = new ChartJS(canvas, {
        type: 'line',
        data,
        options,
      });
    }

    // Cleanup on unmount
    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, [data, options]);

  return <canvas id="fuel-comparison-chart" className='comparisonLine' />;
};

export default FuelComparisonChart;