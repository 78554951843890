import  * as React from 'react';
import { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, CircularProgress, Box, Paper, TableContainer, Pagination, Container, TextField, Button, Grid, IconButton} from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import csv from '../images/csv.png';
import Sidebar from '../Sidebar'
import Footer from '../Footer';
import '../styles/ProductsTurnover.scss'
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

interface TurnData {
    product_id: number;
    product_name: string;
    total_quantity_sold: number;
    periods_active: number;
    inventory_turnover: number;
  }
  
  interface ApiResponse {
    total: number;
    pages: number;
    current_page: number;
    data: TurnData[];
  }
  interface ProductTurnoverSearchProps {
    search: string;
    onSearchChange: (newSearch: string) => void;
}
const ProductsTurnover: React.FC = () => {
    const [turnData, setTurnData] = useState<TurnData[]>([]);
    const [search, setSearch] = useState<string>('');
    const [page, setPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState<boolean>(true);
    const [errorMessage, setErrorMessage] = useState('');
    const url = `${process.env.REACT_APP_API_URL}/api/products-turnover?search=${search}&page=${page}`;
    
    useEffect(() => {
        const fetchData = async () => {
        setLoading(true);
        setErrorMessage('');
            try {
                const response = await fetch(url);
                const result: ApiResponse = await response.json();
                setTurnData(result.data);
                setTotalPages(result.pages);
            } catch (error:any) {
                if (error.response && error.response.data.message) {
                  setErrorMessage(error.response.data.message);
                } else {
                  setErrorMessage('An error occurred while fetching data.');
                }
            } finally {
                setLoading(false);
            }
            };

        fetchData();
    }, [search, page]);
    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
        setPage(1);
    };

    const handleExportCSV = () => {
        if (turnData.length === 0) {
            alert("No data to export.");
            return;
        }
        const csvHeader = ['Product ID', 'Product Name', 'Total Quantity Sold', 'Periods Active (months)', 'Inventory Turnover'];
        const csvRows = turnData.map(item => [
            item.product_id,
            item.product_name,
            item.total_quantity_sold,
            item.periods_active,
            item.inventory_turnover
        ]);

        // Create CSV content
        let csvContent = `${csvHeader.join(',')}\n`; // Add header row
        csvRows.forEach(row => {
            csvContent += row.join(',') + '\n'; // Add each row of data
        });

        // Create a blob and download the CSV file
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Products_Turnover_data_${new Date().toLocaleDateString()}.csv`); // File name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Clean up
    };

    const handleExportPDF = () => {
        const pdf = new jsPDF();
        const title = 'Sales History Report';
        pdf.text(title, 14, 20);

        if (turnData.length === 0) {
            alert("No data to export.");
            return;
        }

        const headers = ['Product ID', 'Product Name', 'Total Quantity Sold', 'Periods Active (months)', 'Inventory Turnover'];
        
        const rows = turnData.map((item) => [
            item.product_id,
            item.product_name,
            item.total_quantity_sold,
            item.periods_active,
            item.inventory_turnover,
        ]);

        autoTable(pdf,{
            head: [headers],
            body: rows,
            startY: 30,
        });
        pdf.save(`Products_Turnover_${new Date().toLocaleDateString()}.pdf`);
    };

    return(
        <div className='turnContent'>
            <Grid container>
                <Grid item xs={12} md={2}>
                    <div>
                        <Sidebar></Sidebar>
                    </div>   
                </Grid>
                <Grid item xs={12} md={10}>
                    <div className='contentT'>
                    <div className='pageHeader'><h1>Products Turnover</h1></div>
                    <div className='pageContent'>
                        <Container className='contentContainer'>
                            <Box mb={2}>
                            <TextField
                                label="Search Product"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                value={search}
                                onChange={handleSearchChange}
                            />
                            </Box>
                            <>
                                <div className='exportButtons'>
                                    <IconButton onClick={handleExportCSV} aria-label="Export as CSV">
                                        <img src={csv} className='csvIcon'/>
                                    </IconButton>
                                    <IconButton onClick={handleExportPDF} aria-label="Export as PDF">
                                        <PictureAsPdfIcon sx={{fontSize:"30px"}}/>
                                    </IconButton>
                                </div>
                                    <div className='table'>
                                        <Table className='tableContent'>
                                            <Table stickyHeader>
                                                <TableHead className='tableHead'>
                                                    <TableRow>
                                                        <TableCell className='tableHeadID'>Product ID</TableCell>
                                                        <TableCell className='tableHead'>Product Name</TableCell>
                                                        <TableCell className='tableHeadQ'>Total Quantity Sold</TableCell>
                                                        <TableCell className='tableHead'>Periods Active (months)</TableCell>
                                                        <TableCell className='tableHead'>Inventory Turnover</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                {loading ? <CircularProgress /> : (
                                                    <TableBody>
                                                        
                                                        {turnData.map((row) => (
                                                            <TableRow key={row.product_id}>
                                                                <TableCell className='cellID'>{row.product_id}</TableCell>
                                                                <TableCell className='cell'>{row.product_name}</TableCell>
                                                                <TableCell className='cellQ'>{row.total_quantity_sold}</TableCell>
                                                                <TableCell className='cell'>{row.periods_active}</TableCell>
                                                                <TableCell className='cell'>{row.inventory_turnover}</TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                )}
                                            </Table>
                                        </Table>
                                    </div>
                                <Box display="flex" justifyContent="center" mt={4} className="pageButtons">
                                    <Button onClick={() => setPage(page - 1)} disabled={page === 1}>
                                        Previous
                                    </Button>
                                    <Button onClick={() => setPage(page + 1)}>
                                                Next
                                    </Button>
                                </Box></>
                        
                        </Container>
                    </div>
                    </div>
                </Grid>
            </Grid>
            <Footer></Footer>
        </div>
    );
};
export default ProductsTurnover