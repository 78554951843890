import React from 'react';
import { Box, Typography, Card, CardContent, Grid } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import './styles/ViewComparisons.scss'

interface SalesCardProps {
  title: string;
  previous: number;
  current: number;
}

const SalesCard: React.FC<SalesCardProps> = ({ title, previous, current }) => {
    const difference = current - previous;
    const percentageChange = (difference / previous) * 100;
    const isPositive = difference >= 0;
  
    return (
      <Card variant="outlined" sx={{ maxWidth: 500, margin: 1 }} className='card'>
        <CardContent className='card'>
          <Typography variant="h6" align="center" gutterBottom>
            {title.toUpperCase()}
          </Typography>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item className='cardBoxes'>
              <Typography variant="subtitle1" color="textSecondary">
                 Previous
              </Typography>
              
              <Typography variant="h5" color="primary">
                    {new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(previous)}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" color="textSecondary">
                 Current
              </Typography>
              <Typography variant="h5" color="success.main">
                    {new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(current)}
              </Typography>
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
            {isPositive ? (
              <ArrowUpwardIcon color="success" />
            ) : (
              <ArrowDownwardIcon color="error" />
            )}
            <Typography variant="h6" color={isPositive ? "success.main" : "error"}>
              {percentageChange.toFixed(2)}%
            </Typography>
          </Box>
        </CardContent>
      </Card>
    );
  };
  
  export default SalesCard;