import  * as React from 'react';
import { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, CircularProgress, Box, Grid, Paper, IconButton} from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Sidebar from '../Sidebar'
import Footer from '../Footer';
import '../styles/StoresPerform.scss'
import dayjs, { Dayjs } from 'dayjs';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import StoresBarChart from '../StoresBarChart';
import csv from '../images/csv.png';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import html2canvas from 'html2canvas';

interface StoresData {
    rank: number;
    site_name: string;
    total_sales: number;
  }
interface ApiResponse {
    data: StoresData[];
  }

const StoresPerform: React.FC = () => {

    const [storesData, setStoresData] = useState<StoresData[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [dateRange, setDateRange] = useState<[Dayjs | null, Dayjs | null]>([dayjs().subtract(1, 'month'), dayjs()]);
    

    useEffect(() => {
    
        const fetchStoresData = async (startDate?: string, endDate?: string) => {
        setLoading(true);
            try {
                let fullurl = `${process.env.REACT_APP_API_URL}/api/stores-performance`;
                if (startDate && endDate) {
                    fullurl += `?start_date=${startDate}&end_date=${endDate}`;
                }
                const response = await fetch(fullurl);
                const result: ApiResponse = await response.json();
                setStoresData(result.data);
            } catch (error) {
                if (error instanceof Error) {
                    setError(error.message);
                }
            }finally {
                setLoading(false);
            }
        };

        if (dateRange[0] && dateRange[1]) {
            const startDate = dateRange[0].format('YYYY-MM-DD');
            const endDate = dateRange[1].format('YYYY-MM-DD');
            fetchStoresData(startDate, endDate);
          }
    }, [dateRange]);

    const handleExportCSV = () => {
        if (storesData.length === 0) {
            alert("No data to export.");
            return;
        }
        const csvHeader = ['Store Name', 'Total Sales'];
        const csvRows = storesData.map(item => [
            item.site_name,
            item.total_sales,
        ]);

        // Create CSV content
        let csvContent = `${csvHeader.join(',')}\n`; // Add header row
        csvRows.forEach(row => {
            csvContent += row.join(',') + '\n'; // Add each row of data
        });

        // Create a blob and download the CSV file
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `stores_data_${new Date().toLocaleDateString()}.csv`); // File name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Clean up
    };

    const handleExportPDF = async () => {
        const pdf = new jsPDF();
        const title = 'Stores Performance Report';
        pdf.text(title, 14, 20);
    
        const headers = ['Rank', 'Store Name', 'Total Sales'];
            
        const rows = storesData.map((item) => [
            item.rank,
            item.site_name,
            new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(item.total_sales),
        ]);
    
        autoTable(pdf,{
            head: [headers],
            body: rows,
            startY: 30,
        });
            
        const chartElement = document.querySelector('.barChart') as HTMLElement;
        if (chartElement) {
            const canvas = await html2canvas(chartElement);
            const imgData = canvas.toDataURL('image/png');
            
            pdf.text('Sales History Bar Chart', 14, 140);
            pdf.addImage(imgData, 'PNG', 14, 150, 150, 150);
        }
    
        pdf.save(`Stores_Performance_${new Date().toLocaleDateString()}.pdf`);
    };

    return(
        <div className='storesPcontent'>
            <Grid container>
                <Grid item xs={12} md={2}>
                    <div>
                        <Sidebar></Sidebar>
                    </div>   
                </Grid>
                <Grid item xs={12} md={10}>
                <div className='contentSP'>
                    <div className='pageHeader'><h1>Stores Performance</h1></div>
                    <div className='pageContent'>
                        <br/>
                        <div className='exportButtons'>
                            <IconButton onClick={handleExportCSV} aria-label="Export as CSV">
                                <img src={csv} className='csvIcon'/>
                            </IconButton>
                            <IconButton onClick={handleExportPDF} aria-label="Export as PDF">
                                <PictureAsPdfIcon sx={{fontSize:"30px"}}/>
                            </IconButton>
                        </div>
                        <br/>
                        <Box className="dateRangePicker">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <SingleInputDateRangeField
                                    label="Choose Date Range"
                                    value={dateRange}
                                    onChange={(newValue) => setDateRange(newValue)}/>
                            </LocalizationProvider>
                        </Box>
                        <div className='viewStoresData'>
                            <Grid container spacing={2} sx={{padding :'10px'}}>
                                <div className='table'>
                                    <Grid item xs={12} md={12}>   
                                            <Table className='tableContent'>
                                                <Table stickyHeader>
                                                <TableHead className='tableHead'>
                                                    <TableRow className='tableHead'>
                                                        <TableCell className='tableHead'>Rank</TableCell>
                                                        <TableCell className='tableHead'>Store Name</TableCell>
                                                        <TableCell className='tableHead'>Total Sales</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                {loading ? (
                                                <div className='loading'>
                                                    <CircularProgress />
                                                </div>
                                                ) : (
                                                <TableBody>
                                                    {storesData.map((item) => (
                                                        <TableRow key={item.rank}>
                                                            <TableCell className='rankColumn'>{item.rank}</TableCell>
                                                            <TableCell className='siteNameColumn'>{item.site_name}</TableCell>
                                                            <TableCell className='salesColumn'>
                                                                {new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(item.total_sales)}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                                )}
                                                </Table>
                                            </Table>    
                                    </Grid>
                                </div>
                                <Grid item xs={12} md={7}>
                                    <Paper className='chartContainer'>
                                        <div className='barChart'>
                                                <StoresBarChart storesData={storesData} />
                                        </div>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
                </Grid>
            </Grid>
            <Footer></Footer>
        </div>
    );
};
export default StoresPerform