import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import SalesHistory from "./components/Dashboard/SalesHistoryPage/SalesHistory";
import DashboardPage from "./components/Dashboard/DashboardPage";
import StoresPerform from "./components/Dashboard/StoresPerform";
import ProductsPerform from "./components/Dashboard/productPerformance/ProductsPerform";
import ProductsTurnover from "./components/Dashboard/ProductsTurnover";
import FuelReports from "./components/Dashboard/FuelReportPage/FuelReports";
import StoreMonitering from "./components/Dashboard/StoreMonitering/StoreMonitering";

const App: React.FC = () => {
    return (
        <div className="App">
            <Router>
                <Routes>
                    <Route path="/" element={<DashboardPage />} />
                    <Route path="/sales-history" element={<SalesHistory />} />
                    <Route path="/stores-perform" element={<StoresPerform />} />
                    <Route
                        path="/products-perform"
                        element={<ProductsPerform />}
                    />
                    <Route
                        path="/products-turnover"
                        element={<ProductsTurnover />}
                    />
                    <Route path="/fuel-reports" element={<FuelReports />} />
                    <Route path="/store-monitoring" element={<StoreMonitering />} />
                </Routes>
            </Router>
        </div>
    );
};

export default App;
