import "./loader.scss";

export default function Loader() {
    return (
        <section className="around__sphere">
            <div className="sphere">
                <div className="sphere__border"></div>
            </div>
        </section>
    );
}
