import React from "react";
import { useEffect, useState } from "react";
import RefundChart from "./Chart";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import {
  Tabs,
  Tab,
  Divider,
  IconButton,
  Radio,
  RadioGroup,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import axios from "axios";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import { rawListeners } from "process";
import { ContinuousColorLegend } from "@mui/x-charts";

interface OptionsProps {
  children?: React.ReactNode;
  name: string;
  value: number;
  api: string;
}
interface StoreOptionsProps {
  children?: React.ReactNode;
  name: string;
  value: number;
  store: string;
}
interface RefundReportApiData {
  [id: string]: number;
}
interface ManagerBarPlot {
  user: string; // Explicitly define 'user' as a string
  [key: string]: number[] | string; // All other keys can be number arrays or strings
}
type Params = {
  type: string;
};

const ReportPage: React.FC = () => {
  let Url: string = `${process.env.REACT_APP_REFUNDS_URL}/analytics`;

  const [reportType, setReportType] = useState<any | null>(null);
  const [params, setParams] = useState<any>(null);
  const [availlableParams, setAvailableParams] = useState<any>(null);
  const [extraParams, setExtraParams] = useState<any>(null);
  const [extraParamsList, setExtraParamsList] = useState<any>(null);
  const [reportData, setReportData] = useState<
    RefundReportApiData | ManagerBarPlot[] | null
  >(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userList, setUserList] = useState<boolean>(false);
  const [categories, setCatogries] = useState<string[]>([]);
  const [selectedStore, setselectedStore] = useState<any>('');
  const [isStoreFieldVisible, setisStoreFieldVisible] =
    useState<boolean>(false);
  const [isTimeFieldVisible, setisTimeFieldVisible] = useState<boolean>(false);
  const [dates, setDates] = useState<any>("");
  const [isValidDate, setIsValidDate] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<any>(null);
  const reportTypeController = new AbortController();

  useEffect(() => {
    if (reportType?.length > 0) {
      console.log("coming here in report type changed ", reportType);
      FetchReportType(Url);
    }
    return () => reportTypeController.abort();
  }, [reportType]);
  // console.log(errorMessage)
  useEffect(() => {
    setIsLoading(true);
    if(extraParams || selectedStore)FetchDataUsingExtraParams(Url);
    return () => reportTypeController.abort();
  }, [extraParams, selectedStore]);

  useEffect(() => {
    if (reportType == "shop_trend") {
      setIsLoading(true);
      FetchDataUsingExtraParams(Url);
    } else if (params) {
      setIsLoading(true);
      FetchDataUsingType(Url, params);
    }

    return () => reportTypeController.abort();
  }, [params]);

  useEffect(() => {
    // console.log(dates);

    if (dates.startdate && dates.enddate) {
      if (
        dates.startdate &&
        dates.enddate &&
        !isValidDateRange(dates.startdate, dates.enddate)
      )
        alert("Date range error:");
      if (
        selectedStore == null ||
        selectedStore == undefined ||
        selectedStore == ""
      )
        alert("Please select the store");
      setIsLoading(true);
      FetchDataUsingExtraParams(Url).then((res: any) => {
        console.log("final is here ", res);
        // setReportData(res);
        setIsLoading(false);
      });
    }

    return () => reportTypeController.abort();
  }, [dates]);

  const setError = (err: any, num: any) => {
    if (err?.message) setErrorMessage(`Error ${num}: ${err?.message}  `);
    else if(err?.data) setErrorMessage(null)
    setIsLoading(false);
  };

  function isObject(value: unknown): boolean {
    return typeof value === "object" && value !== null && !Array.isArray(value);
  }

  const sortParams = (data: any) => {
    let updatedData = null;

    if (isObject(data) && data.hasOwnProperty("type")) updatedData = data.type;
    if (
      isObject(data) &&
      (data.hasOwnProperty("types") || data.hasOwnProperty("type"))
    )
      updatedData = data["types"] || data["type"];
    if (isObject(data) && data.hasOwnProperty("criteria"))
      updatedData = data["criteria"];
    if (
      isObject(data) &&
      data?.hasOwnProperty("siteName") &&
      reportType == "shop_hourly_trend"
    )
      updatedData = data["siteName"];

    // console.log(
    //   "data is here",
    //   data,
    //   "updateddata is here",
    //   updatedData,
    //   "isObject",
    //   isObject(data),
    //   "has own proprty ",
    //   data.hasOwnProperty("type"),
    //   "\nconditions",
    //   isObject(data) &&
    //     (data.hasOwnProperty("types") || data.hasOwnProperty("type"))
    // );
    return updatedData;
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setParams((event.target as HTMLInputElement).value);
    setExtraParams(null);
    setReportData(null);
  };

  const handleExtraRadioChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    // console.log(event.target.value);
    setExtraParams((event.target as HTMLInputElement).value);
    setReportData(null);
  };

  const sortAndSetDataForManagersBarPlot = (apiRes: any) => {
    // console.log(apiRes);
    let data: ManagerBarPlot[] = [];

    let obj: any = {};
    let values: any[] = [];
    const categories: string[] = [];
    apiRes && Object.keys(apiRes[0])?.forEach((el) => {
      console.log("ele is here ", el);
      if (el == "number_of_discounts" || el.includes('discount')) categories.push("Discounts");
      if (el == "number_of_refunds" || el.includes('refund')) categories.push("Refunds");
    });
    apiRes?.forEach((el: any) => {
      obj = {};
      values = [];
      Object.entries(el).map((item: any) => {
        if (item[0] == "user_name") {
          obj["name"] = item[1];
          categories.push(item[1]);
        } else {
          values.push(item[1]);
        }
      });
      data.push({ ...obj, data: values, pointWidth: 10 });
    });
    setCatogries(categories);
    console.log("######", data);
    setReportData(data);
  };

  const FetchDataUsingType = async (url: string, params: any) => {
    let fullUrl = `${url}/${reportType}`;
    if (reportType == "type_trend") {
      fullUrl = fullUrl + `?type=${params}`;
    } else if (reportType == "reason_freq") {
      fullUrl = fullUrl + `?type=${params}`;
    } else if (reportType == "reason_trend") {
      fullUrl = fullUrl + `?type=${params}`;
    } else if (reportType == "managers_barplot")
      fullUrl = fullUrl + `?criteria=${params}`;
    else if (reportType == "each_manager_trend")
      fullUrl = fullUrl + `?type=${params}`;
    else if (reportType == "shop_trend") fullUrl = fullUrl + `?type=${params}`;
    // else if (reportType == 'shop_hourly_trend')
    //   fullUrl = fullUrl + `?sitename=${extraParams}`
    let res: any = null;
    if (reportType == "shop_trend") {
    } else {
      // console.log("fetchdata using type", fullUrl, params);
      res = await axios
        .get(fullUrl, { signal: reportTypeController.signal })
        .then((res) => {
          setError(res, null);
          return res.data;
        })
        .catch((err) => setError(err, 2));
    }

    if (reportType == "reason_trend") {
      setExtraParamsList(res?.reason);
    } else if (reportType == "managers_barplot")
      sortAndSetDataForManagersBarPlot(res);
    else {
      setReportData(res);
    }
    setIsLoading(false);
    return res;
  };

  const FetchReportType = async (url: string) => {
    setIsLoading(true);
    url = `${url}/${reportType}`;
    const res = await axios
      .get(url)
      .then((res) => {
        setError(res, null);
        return res.data;
      })
      .catch((err) => setError(err, 1));
    if (res) {
      setIsLoading(false);
      setExtraParamsList(res.user_name);
      if (reportType == "shop_trend") {
        setisStoreFieldVisible(true);
      }
      if (reportType == "shop_hourly_trend") {
        setisStoreFieldVisible(true);
        setisTimeFieldVisible(true);
      } else setAvailableParams(sortParams(res));
    }
  };
  const isValidDateRange: any = (startDate: any, endDate: any) => {
    // Convert strings to Date objects
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Check if start date is after end date
    return start <= end;
  };

  const handleDatePicker = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    // if(!isValidDateRange(dates.startdate, dates.enddate)) alert('Date range error:')
    setDates((prevDates: any) => {
      return {
        ...prevDates,
        [name]: value, // Updates startdate or enddate based on input name
      };
    });
  };

  const FetchDataUsingExtraParams = async (url: string) => {
    let fullUrl: string = `${url}/${reportType}?type=${params}`;
    if (reportType == "reason_trend") {
      fullUrl = fullUrl + `&reason=${extraParams}`;
    } else if (reportType == "reason_freq") {
      fullUrl = fullUrl + `?type=${params}`;
    } else if (reportType == "reason_trend") {
      fullUrl = fullUrl + `?type=${params}`;
    } else if (reportType == "each_manager_trend") {
      fullUrl = fullUrl + `&user_name=${extraParams}`;
    } else if (reportType == "shop_trend")
      fullUrl = fullUrl + `&sitename=${selectedStore}`;
    else if (reportType == "shop_hourly_trend")
      fullUrl =
        `${url}/${reportType}` +
        `?shopName=${selectedStore}&fromDate=${dates.startdate}&tillDate=${dates.enddate}`;

        
    if (extraParams || selectedStore) {

      const res = await axios
        .get(fullUrl, { signal: reportTypeController.signal }).then((res) => {
          setError(res, null);
          return res.data;
        })
        .catch((err) => {
          console.log(err);
          setError(err, 3);
        });


      console.log("extra params ", res);

      if (res && reportType == "shop_hourly_trend") {
        const formattedData = res.reduce((acc: any, item: any) => {
          acc[item["Time Interval"]] = item["Transaction Count"];
          return acc;
        }, {} as Record<string, number>);

        setReportData(formattedData);
      } else setReportData(res);
      setIsLoading(false);
    }

  };

  const handleChange = (event: SelectChangeEvent) => {

    setReportType(event.target.value as any);
    setAvailableParams(null);
    setExtraParamsList(null);
    setReportData(null);
    setExtraParams(null);
    setParams(null);
    setisStoreFieldVisible(false);
    setisTimeFieldVisible(false);
    setselectedStore("");

  };

  const handleChangeStore = (event: SelectChangeEvent) => {
    setselectedStore(event.target.value);
    if (reportType == "shop_hourly_trend") setisTimeFieldVisible(true);
  };

  const GraphOptions: any = () => {
    return [
      { name: "Refund/Discount", value: 0, api: "type_trend" },
      { name: "Reason frequency", value: 1, api: "reason_freq" },
      { name: "Reason Trend", value: 2, api: "reason_trend" },
      { name: "Manager barplot", value: 3, api: "managers_barplot" },
      // { name: "Manager Table", value: 4, api: "managers_table" },
      { name: "Each Manager Trend", value: 5, api: "each_manager_trend" },
      { name: "Shop Trend", value: 6, api: "shop_trend" },
      { name: "Shop Hourly Trend", value: 7, api: "shop_hourly_trend" },
      // { name: "Product summary", value: 8, api: "product_summary" },
    ];
  };

  const GraphSelectOptions: any = (data: any[]) => {
    let modifiedData = data.map((el: any, i: number) => {
      return { name: "Refund/Discount", value: i, param: "type_trend" };
    });

    return modifiedData;
  };
  const capitalizeFirstLetter = (str: string): string => {
    if (!str) return ""; // Handle empty string case
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  // const paramOptions: any = () => {
  //   if (params || params?.lenght > 0) {
  //     let temp = params.map((e: any) => {
  //       return { value: e };
  //     });
  //     setAvailableParams(temp);
  //   } else {
  //     let temp = [
  //       { name: "Refund/Discount", value: 0, api: "type_trend" },
  //       { name: "Reason frequency", value: 1, api: "reason_freq" },
  //       { name: "Reason Trend", value: 2, api: "reason_trend" },
  //       { name: "Manager barplot", value: 3, api: "managers_barplot" },
  //       { name: "Manager Table", value: 4, api: "managers_table" },
  //       { name: "Each Manager Trend", value: 5, api: "each_manager_trend" },
  //       { name: "Shop Trend", value: 5, api: "shop_trend" },
  //       { name: "Shop Hourly Trend", value: 6, api: "shop_hourly_trend" },
  //       { name: "Product summary", value: 7, api: "product_summary" },
  //     ];
  //     setAvailableParams(temp);
  //   }
  // };

  const storeOptions: any = () => {
    return [
      { name: "Rutherglen", value: 0, store: "rutherglen" },
      { name: "Bridgeton", value: 1, store: "bridgeton" },
      { name: "Bearsden", value: 2, store: "bearsden" },
      { name: "Autoport", value: 3, store: "autoport" },
      { name: "Braeside", value: 4, store: "braeside" },
      { name: "Crowwood", value: 5, store: "crowwood" },
      { name: "Coatbridge", value: 6, store: "coatbridge" },
      { name: "Dumbarton", value: 7, store: "dumbarton" },
      { name: "Strathclyde", value: 8, store: "strathclyde" },
      { name: "Sauchiehall", value: 9, store: "sauchiehall" },
      { name: "Wishaw", value: 10, store: "wishaw" },
    ];
  };

  const getChartType = (input: string): string => {
    let chartType = "line";
    if (reportType == "type_trend") chartType = "area";
    else if (reportType == "reason_freq") chartType = "bar";

    return chartType;
  };
  return (
    <div style={{ padding: 4, height: "100%" }}>
      <div
        style={{
          display: "flex ",
          justifyContent: "space-between",
          backgroundColor: "white",
        }}
      >
        <div
          style={{
            display: "flex",
            minHeight: 480,
            flexDirection: "column",
            minWidth: "30vw",
            padding: "20px",
            margin: "10px",
            backgroundColor: "white",
            // justifyContent: "space-evenly",
            maxWidth: "20vw",
            paddingRight: 20,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              // justifyContent: "space-evenly",
              textAlign: "left",
              // alignItems: "left",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                gap: 10,
              }}
            >
              <FormLabel id="demo-radio-buttons-group-label">
                Choose Discount/Refund type
              </FormLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={reportType}
                onChange={handleChange}
              >
                {GraphOptions().map((ele: OptionsProps) => {
                  return (
                    <MenuItem value={ele.api} key={ele.value}>
                      {ele.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
            {availlableParams && (
              <div style={{ minHeight: 140, marginTop: "30px" }}>
                {/* <p style={{}}>Choose options </p>{" "} */}

                <FormLabel id="demo-radio-buttons-group-label">
                  Choose what do you want to see
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  onChange={handleRadioChange}
                >
                  {availlableParams?.map((e: any) => {
                    return (
                      <FormControlLabel
                        value={e}
                        control={<Radio size="small" />}
                        label={capitalizeFirstLetter(e)}
                      />
                    );
                  })}
                </RadioGroup>
              </div>
            )}

            {extraParamsList && (
              <div style={{ minHeight: 140, textAlign: "left" }}>
                {/* <p style={{}}>Choose options </p>{" "} */}

                <FormLabel id="demo-radio-buttons-group-label">
                  Choose the reason
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  onChange={handleExtraRadioChange}
                >
                  {extraParamsList?.map((e: any) => {
                    return (
                      <FormControlLabel
                        value={e}
                        control={<Radio size="small" />}
                        label={e}
                      />
                    );
                  })}
                </RadioGroup>
              </div>
            )}
            {isStoreFieldVisible && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                  gap: 10,
                  marginTop: 20,
                }}
              >
                {/* <p style={{}}>Choose options </p>{" "} */}

                <FormLabel id="demo-radio-buttons-group-label">
                  Choose Store
                </FormLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedStore}
                  onChange={handleChangeStore}
                >
                  {storeOptions().map((ele: StoreOptionsProps) => {
                    return (
                      <MenuItem value={ele.store} key={ele.value}>
                        {ele.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
            )}
            {isTimeFieldVisible && selectedStore && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  gap: 10,
                  marginTop: 20,
                  height: "40px",
                }}
              >
                <FormLabel id="demo-radio-buttons-group-label">
                  Select dates
                </FormLabel>
                <input
                  style={{
                    padding: "8px",
                    fontSize: "14px",
                    border: "1px solid #ccc",
                    borderRadius: "3px",
                    outline: "none",
                    textAlign: "left",
                    width: "150px",
                  }}
                  name="startdate"
                  type="date"
                  onChange={(e: any) => {
                    handleDatePicker(e);
                  }}
                />
                <input
                  style={{
                    padding: "8px",
                    fontSize: "14px",
                    border: "1px solid #ccc",
                    borderRadius: "3px",
                    outline: "none",
                    textAlign: "left",
                    width: "150px",
                  }}
                  name="enddate"
                  type="date"
                  onChange={(e: any) => {
                    handleDatePicker(e);
                  }}
                />
              </div>
            )}
          </div>
          {/* <div> Input select goes here</div> */}
          <div style={{ alignContent: "flex-end", flex: 1 }}>
            <p>
              First choose the type of chart, for example- you can choose
              Discount type and then see trend. then on righ side of the screen
              chart will appear and you can see the chart presenting overll
              dicount offered with respect to the dates{" "}
            </p>
          </div>
        </div>

        <div style={{ flex: 1, padding: "10px" }}>
          {isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: "bold",
                textAlign: "center",
                height: "80%",
                border: "4px solid transparent", // Transparent border initially
                backgroundImage:
                  "linear-gradient(white, white), linear-gradient(45deg, pink, blue)", // Inner and gradient layers
                backgroundOrigin: "border-box",
                backgroundClip: "content-box, border-box",
                borderRadius: "8px", // Optional: Rounded corners
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)", // Light shadow
              }}
            >
              Loading...
            </div>
          ) : errorMessage ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: "bold",
                textAlign: "center",
                height: "80%",
                border: "4px solid transparent", // Transparent border initially
                backgroundImage:
                  "linear-gradient(white, white), linear-gradient(45deg, pink, blue)", // Inner and gradient layers
                backgroundOrigin: "border-box",
                backgroundClip: "content-box, border-box",
                borderRadius: "8px", // Optional: Rounded corners
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)", // Light shadow
              }}
            >
              {errorMessage}
            </div>
          ) : reportData ? (
            <RefundChart
              reportdata={reportData}
              params={params}
              reportType={reportType}
              chartType={getChartType}
              categories={categories}
              extraParams={extraParams}
              selectedStore={selectedStore}
              errorMessage={errorMessage}
            />
          ) : (
            // <></>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: "bold",
                textAlign: "center",
                height: "80%",
                border: "4px solid transparent", // Transparent border initially
                backgroundImage:
                  "linear-gradient(white, white), linear-gradient(45deg, pink, blue)", // Inner and gradient layers
                backgroundOrigin: "border-box",
                backgroundClip: "content-box, border-box",
                borderRadius: "8px", // Optional: Rounded corners
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)", // Light shadow
              }}
            >
              No feild Selected
              <br></br>
              Please select/choose the given options to view the chart
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReportPage;
