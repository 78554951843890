import  * as React from 'react';
import { useEffect, useState } from 'react';
import {
  CircularProgress, Container, Select, MenuItem, FormControl, InputLabel, Box, Typography,
  Grid
} from '@mui/material';
import SalesCard from './SalesComparisonsHead';
import ParentCategoryComparison from './ParentCategoryComparison';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import './styles/ViewComparisons.scss'

interface SalesData {
  category_id: number;
  current_sales: number;
  previous_sales: number;
}
interface ApiResponse {
  sales_comparison: SalesData[];
  current_period_start: string;
  current_period_end: string;
  previous_period_start: string;
  previous_period_end: string;
}


const ViewComparisons: React.FC = () => {
  const [salesData, setSalesData] = useState<SalesData[]>([]);
  const [parentSalesData, setParentSalesData] = useState<SalesData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [loading2, setLoading2] = useState<boolean>(true);
  const [periodType, setPeriodType] = useState<string>('daily');
  const [site, setSite] = useState<string>('all');
  const [dateRange, setDateRange] = useState<[Dayjs | null, Dayjs | null]>([null, null]);
  const [periods, setPeriods] = useState<{
    current_period_start: string;
    current_period_end: string;
    previous_period_start: string;
    previous_period_end: string;
  }>({
    current_period_start: '',
    current_period_end: '',
    previous_period_start: '',
    previous_period_end: ''
  });
  useEffect(() => {
    const handleFetchSales = async () => {
      setLoading(true);
      try{
        const dateRangeQuery = dateRange[0] && dateRange[1] ? `${dateRange[0].format('YYYY-MM-DD')},${dateRange[1].format('YYYY-MM-DD')}` : '';
        const periodTypeQuery = dateRangeQuery ? '' : `period_type=${periodType}&`;
        const url =`${process.env.REACT_APP_API_URL}/api/sales_comparisons_head?${periodTypeQuery}&site=${site}${dateRangeQuery ? `&date_range=${dateRangeQuery}` : ''}`;
        const response = await fetch(url);
        const result: ApiResponse = await response.json();
        if (result.sales_comparison) {
          setSalesData(result.sales_comparison);
          setPeriods({
            current_period_start: result.current_period_start,
            current_period_end: result.current_period_end,
            previous_period_start: result.previous_period_start,
            previous_period_end: result.previous_period_end
          });
        } else {
          setSalesData([]);
        }
      }
      catch (error) {
        if (error instanceof Error) {
          console.error('Error fetching sales data:', error);
        }
      } finally {
          setLoading(false);
      }
    };

    const handleFetchParentSales = async () => {
      setLoading2(true);
      try {
        const dateRangeQuery = dateRange[0] && dateRange[1] ? `${dateRange[0].format('YYYY-MM-DD')},${dateRange[1].format('YYYY-MM-DD')}` : '';
        const periodTypeQuery = dateRangeQuery ? '' : `period_type=${periodType}&`;
        const url = `${process.env.REACT_APP_API_URL}/api/sales_comparisons_parent_categories?${periodTypeQuery}&site=${site}${dateRangeQuery ? `&date_range=${dateRangeQuery}` : ''}`;
        const response = await fetch(url);
        const result: ApiResponse = await response.json();
        if (result.sales_comparison) {
          setParentSalesData(result.sales_comparison);
        } else {
          setParentSalesData([]);
        }
      } catch (error) {
        if (error instanceof Error) {
          console.error('Error fetching parent category sales data:', error);
        }
      }finally {
        setLoading2(false);
      }
    };

    handleFetchSales();
    handleFetchParentSales();
}, [periodType, site, dateRange]);
  const categoryTitles: Record<number, string> = {
    1362995: 'FUEL',
    1350729: 'PAYPOINT',
    1379297: 'RT LOTTERY',
    1399047: 'RT SCRATCHCARDS'
  };
  const formatDateRange = (dateRange: [Dayjs | null, Dayjs | null]) => {
    if (!dateRange[0] || !dateRange[1]) return 'Not selected';
    return `${dateRange[0].format('YYYY-MM-DD')} to ${dateRange[1].format('YYYY-MM-DD')}`;
  };
  
  return(
    <Container>
      <Box component="form" sx={{ marginBottom: 4 }}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel id="period-type-label">Period Type</InputLabel>
              <Select
                labelId="period-type-label"
                value={periodType}
                label="Period Type"
                onChange={(e) => setPeriodType(e.target.value)}
              >
                <MenuItem value="daily">Daily</MenuItem>
                <MenuItem value="weekly">Weekly</MenuItem>
                <MenuItem value="monthly">Monthly</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel id="site-filter-label">Site</InputLabel>
              <Select
                labelId="site-filter-label"
                value={site}
                label="Site"
                onChange={(e) => setSite(e.target.value)}
              >
                <MenuItem value="all">ALL</MenuItem>
                <MenuItem value="19191">Autoport</MenuItem>
                <MenuItem value="24469">Dumbarton Rd</MenuItem>
                <MenuItem value="24580">Strathclyde</MenuItem>
                <MenuItem value="24796">Rutherglen</MenuItem>
                <MenuItem value="24897">Sauchiehall</MenuItem>
                <MenuItem value="24923">Braeside</MenuItem>
                <MenuItem value="24974">Coatbridge</MenuItem>
                <MenuItem value="24753">Hillington</MenuItem>
                <MenuItem value="24701">Bearsden</MenuItem>
                <MenuItem value="24922">Bridgeton</MenuItem>
                <MenuItem value="25251">Wishaw</MenuItem>
                <MenuItem value="24195">Head Office</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <SingleInputDateRangeField
                label="Choose Date Range"
                value={dateRange}
                onChange={(newValue) => setDateRange(newValue)}/>
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Box>
      {/* Current Choices Display */}
      <Box mt={3} className="selectionList">
        <Typography variant="h5" className='selectionListTitle'>Current Selections:</Typography>
        <Typography><p className='otherT'>Period Type: </p> {periodType.charAt(0).toUpperCase() + periodType.slice(1)}</Typography>
        <Typography><p className='otherT'>Site: </p> {site === 'all' ? 'All Sites' : site}</Typography>
        <Typography><p className='otherT'>Date Range: </p> {formatDateRange(dateRange)}</Typography>
        <br></br>
        <Typography className='currentStart'><p>Current Start:</p> {periods.current_period_start}</Typography>
        <Typography className='currentEnd'><p>Current End: </p> {periods.current_period_end}</Typography>
        <br></br>
        <Typography className='prevStart'><p>Previous Start: </p> {periods.previous_period_start}</Typography>
        <Typography className='prevEnd'><p>Previous End: </p> {periods.previous_period_end}</Typography>
      </Box>

      {loading ? (
        <Container sx={{ textAlign: 'center', marginTop: 5 }}>
          <CircularProgress />
        </Container>
      ) : (
        <>
        <Grid container spacing={0.5} justifyContent="center" className='cardsContainer'>
          {salesData && salesData.length > 0 ? (
            salesData.map((data) => (
              <Grid item key={data.category_id}>
                <SalesCard
                  title={categoryTitles[data.category_id]}
                  previous={data.previous_sales}
                  current={data.current_sales}
                />
            </Grid>
          ))
        ) : (
          <Typography variant="h6">No data available for the selected filters.</Typography>
        )}
        </Grid>
        {loading2 ? (
          <Container sx={{ textAlign: 'center', marginTop: 5 }}>
            <CircularProgress />
          </Container>
        ) : (
        <div className='reportingChart'>
        <ParentCategoryComparison data={parentSalesData}/>
        </div>)}
        </>
      )}
  </Container>
);
};

export default ViewComparisons;