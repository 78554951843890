import React, { useEffect, useState } from 'react';
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography, Paper, IconButton } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import csv from '../../../images/csv.png';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

interface FuelPrice {
    fuelType: string;
    fuelPrice: number;
    siteName: string;
    latest_time: string;
  }
  
  const validFuelTypes = [
    "Diesel", 
    "Super Diesel", 
    "Ultimate Diesel", 
    "Unleaded", 
    "Super Unleaded", 
    "Ultimate Unleaded"
  ];
  
  const FuelPriceTable: React.FC = () => {
    const [fuelPrices, setFuelPrices] = useState<FuelPrice[]>([]);
    const url = `${process.env.REACT_APP_API_URL}/api/recent-fuel-price-changes`;
  
    const fetchFuelPrices = async () => {
      try {
        const response = await fetch(url);
        const data: FuelPrice[] = await response.json();
        const filteredData = data.filter(row => validFuelTypes.includes(row.fuelType));
        setFuelPrices(filteredData);
      } catch (error) {
        console.error('Error fetching fuel prices:', error);
      }
    };
  
    useEffect(() => {
      fetchFuelPrices();
    }, []);
  
    const transformData = (fuelPrices: FuelPrice[]) => {
      const transformed: Record<string, any> = {};
  
      fuelPrices.forEach(({ siteName, fuelType, fuelPrice, latest_time }) => {
        if (!transformed[siteName]) {
          transformed[siteName] = { siteName, latest_time };
        }
        transformed[siteName][fuelType] = fuelPrice;
      });
  
      return Object.values(transformed);
    };
  
    const data = transformData(fuelPrices);

    const handleExportCSV = () => {
      if (fuelPrices.length === 0) {
        alert("No data to export.");
        return;
      }
      const csvHeader = ['Site Name','Fuel Price', 'Fuel Type', 'Latest Update Time'];
      const csvRows = fuelPrices.map(item => [
        item.siteName,
        item.fuelPrice,
        item.fuelType,
        item.latest_time,
      ]);

      // Create CSV content
      let csvContent = `${csvHeader.join(',')}\n`; // Add header row
      csvRows.forEach(row => {
          csvContent += row.join(',') + '\n'; // Add each row of data
      });

      // Create a blob and download the CSV file
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Recent_Fuel_Price_Changes_${new Date().toLocaleDateString()}.csv`); // File name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Clean up
    };

    const handleExportPDF = () => {
      const pdf = new jsPDF();
      const title = 'Current Fuel Prices Report';
      pdf.text(title, 14, 20);

      if (fuelPrices.length === 0) {
          alert("No data to export.");
          return;
      }

      const headers = ['Site Name','Fuel Price', 'Fuel Type', 'Latest Update Time'];
      
      const rows = fuelPrices.map((item) => [
        item.siteName,
        item.fuelPrice,
        item.fuelType,
        item.latest_time,
      ]);

      autoTable(pdf,{
          head: [headers],
          body: rows,
          startY: 30,
      });
      pdf.save(`Recent_Fuel_Price_Changes_${new Date().toLocaleDateString()}.pdf`);
    };

    return (
        <>
        <div className='exportButtons'>
          <IconButton onClick={handleExportCSV} aria-label="Export as CSV">
            <img src={csv} className='csvIcon'/>
          </IconButton>
          <IconButton onClick={handleExportPDF} aria-label="Export as PDF">
            <PictureAsPdfIcon sx={{fontSize:"30px"}}/>
          </IconButton>
        </div>
        <Typography variant="h5" gutterBottom className='fuelPriceTitle'>
          Recent Fuel Price Changes
        </Typography>
        
        <div className='fuelPriceTable'>
                <Table >
                    <TableHead className='fuelPriceHead'>
                    <TableRow>
                        <TableCell className='fuelPriceHead'>Site Name</TableCell>
                        {validFuelTypes.map(type => (
                        <TableCell className='fuelPriceHead'  key={type}>{type}</TableCell>
                        ))}
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {data.map((row, index) => (
                        <TableRow key={index}>
                        <TableCell className='fuelPriceSite' data-label="Site Name">{row.siteName.toUpperCase()}</TableCell>
                        {validFuelTypes.map(type => (
                            <TableCell key={type} data-label={type}>{row[type] !== undefined ? row[type] : 'N/A'}</TableCell>
                        ))}
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
        </div>
        
      </>
    );
  };
  
  export default FuelPriceTable