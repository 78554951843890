import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, Legend, ResponsiveContainer } from 'recharts';
import { Typography, Box } from '@mui/material';
import './styles/ViewComparisons.scss'

interface ParentCategoryComparisonProps {
  data: Array<{
    category_id: number;
    current_sales: number;
    previous_sales: number;
  }>;
}

const ParentCategoryComparison: React.FC<ParentCategoryComparisonProps> = ({ data }) => {
  const parentCategoryTitles: Record<number, string> = {
    1078492: 'AMERICAN RANGE REPORT',
    1082336: 'CAFE/COFFEE REPORT',
    1350738: 'HOAGIES',
    1315706: 'PICK AND MIX REPORT',
    1349478: 'RT CAR CARE',
    1349484: 'RT CHILLER',
    1349476: 'RT CONFECTIONERY REPORT',
    1349482: 'RT GROCERY VAT',
    1349495: 'RT GROCERY ZERO VAT',
    1349487: 'RT OFF LICENCE',
    1349489: 'RT PROTIEN',
    1349477: 'RT TOBACCO',
    1082335: 'SLUSH REPORT',
    1350728: 'TUBBEES',
    1407820: 'TUBBEES BAKING REPORT',
    1347350: 'V4U ECIGS'
  };

  const formattedData = data.map(item => ({
    name: parentCategoryTitles[item.category_id],
    Current: item.current_sales,
    Previous: item.previous_sales,
  }));

  return (
    <Box mt={5}>
      <Typography variant="h6" gutterBottom>
        Reporting Category Sales
      </Typography>
      <ResponsiveContainer width="90%" height={700}>
        <BarChart
          layout="vertical" // Change the layout to vertical
          data={formattedData}
          margin={{ top: 5, right: 20, left: 50, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis type="number" />  {/* Sales totals on X-axis */}
          <YAxis dataKey="name" type="category" className='yAxis'/>  {/* Category names on Y-axis */}
          <Tooltip />
          <Legend />
          <Bar dataKey="Current" fill="#248324" />
          <Bar dataKey="Previous" fill="#296ead" />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default ParentCategoryComparison;