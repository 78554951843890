import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Typography, Select, MenuItem, Pagination, SelectChangeEvent, TableFooter, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import '../styles/FuelReports.scss';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import csv from '../../../images/csv.png';
import autoTable from 'jspdf-autotable';
import jsPDF from 'jspdf';

interface DriveOff {
  fuelTransactionId: number;
  paymentTime: string;
  fuelValue: number;
  fuelQuantity: number;
  pumpNo: number;
  siteName: string;
}

const DriveOffTable: React.FC = () => {
  const [driveOffs, setDriveOffs] = useState<DriveOff[]>([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [totalDriveOffValue, setTotalDriveOffValue] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [filter, setFilter] = useState('all');
  const [totalPages, setTotalPages] = useState(1);
  const [openCSVDialog, setOpenCSVDialog] = useState(false);
  const [openPDFDialog, setOpenPDFDialog] = useState(false);
  const [exportPerPage, setExportPerPage] = useState(20); // Per page for CSV export
  const url = `${process.env.REACT_APP_API_URL}/api/drive-off-transactions?page=${page}&per_page=${perPage}&filter=${filter}`

  const fetchDriveOffs = async (page: number, perPage: number, filter: string) => {
    try {
      const response = await fetch(url);
      const data = await response.json();
      setDriveOffs(data.data);
      setTotalDriveOffValue(data.total_drive_off_value);
      setPercentage(data.percentage);
      setTotalPages(Math.ceil(data.total_count / perPage));
    } catch (error) {
      console.error('Error fetching drive-offs:', error);
    }
  };

  useEffect(() => {
    fetchDriveOffs(page, perPage, filter);
  }, [page, perPage, filter]);

  const handleFilterChange = (event: SelectChangeEvent<string>) => {
    setFilter(event.target.value as string);
    setPage(1); // Reset to the first page when filter changes
  };

  const handleCSVDialogOpen = () => {
      setOpenCSVDialog(true);
  };

  const handleCSVDialogClose = () => {
      setOpenCSVDialog(false);
  };

  const handlePDFDialogOpen = () => {
    setOpenPDFDialog(true);
  };

const handlePDFDialogClose = () => {
    setOpenPDFDialog(false);
  };

  const handleExportCSV = async () => {
      const csvUrl = `${process.env.REACT_APP_API_URL}/api/drive-off-transactions?page=${page}&per_page=${exportPerPage}&filter=${filter}`;
      try {
        const response = await fetch(csvUrl);
        const data = await response.json();
        const csvData = data.data;

      if (csvData.length === 0) {
          alert("No data to export.");
          return;
      }
      const csvHeader = ['Transaction Id', 'Drive Off Day', 'Drive Off Date', 'Fuel Quantity', 'Fuel Value', 'Pump No', 'Site Name'];
      const csvRows = csvData.map((item: { fuelTransactionId: any; paymentTime: any; fuelValue: any; fuelQuantity: any; pumpNo: any; siteName: any; }) => [
        item.fuelTransactionId,
        item.paymentTime,
        item.fuelQuantity,
        item.fuelValue,
        item.pumpNo,
        item.siteName,
      ]);

      // Create CSV content
      let csvContent = `${csvHeader.join(',')}\n`; // Add header row
      csvRows.forEach((row: any[]) => {
          csvContent += row.join(',') + '\n'; // Add each row of data
      });

      // Create a blob and download the CSV file
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Historical_Drive_Offs_${new Date().toLocaleDateString()}.csv`); // File name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Clean up
      } catch (error) {
          console.error("Failed to export CSV", error);
      } finally {
          handleCSVDialogClose();
      }
  };

  const handleExportPDF = async () => {
    const pdf = new jsPDF();
    const title = 'Historical Drive Offs';
    pdf.text(title, 14, 20);

    const pdfUrl = `${process.env.REACT_APP_API_URL}/api/drive-off-transactions?page=${page}&per_page=${exportPerPage}&filter=${filter}`;
    try {
      const response = await fetch(pdfUrl);
      const data = await response.json();
      const pdfData = data.data;

      if (pdfData.length === 0) {
        alert("No data to export.");
        return;
      }
      const headers = ['Transaction Id', 'Drive Off Date', 'Fuel Quantity', 'Fuel Value', 'Pump No', 'Site Name'];
            
      const rows = pdfData.map((item: { fuelTransactionId: any; paymentTime: any; fuelValue: any; fuelQuantity: any; pumpNo: any; siteName: any; }) => [
        item.fuelTransactionId,
        item.paymentTime,
        item.fuelQuantity,
        new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(item.fuelValue),
        item.pumpNo,
        item.siteName,
      ]);

      autoTable(pdf,{
          head: [headers],
          body: rows,
          startY: 30,
      });

      pdf.save(`sales_history_${new Date().toLocaleDateString()}.pdf`);
    } catch (error) {
      console.error("Failed to export PDF", error);
    } finally {
      handlePDFDialogClose();
    }
  };

  return (
    <>
       <div className='exportButtons'>
          <IconButton onClick={handleCSVDialogOpen} aria-label="Export as CSV">
            <img src={csv} className='csvIcon'/>
          </IconButton>
          <IconButton onClick={handlePDFDialogOpen} aria-label="Export as PDF">
            <PictureAsPdfIcon sx={{fontSize:"30px"}}/>
          </IconButton>
        </div>
    <Typography variant="h5" className='driveOffHeader'>Historical Drive Offs</Typography>
    <br/>
    <div className='driveOffTableOptions'>
        <p>Select Time Period: </p>
        <Select value={filter} onChange={handleFilterChange}>
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="week">Last Week</MenuItem>
            <MenuItem value="month">Last Month</MenuItem>
            <MenuItem value="year">Last Year</MenuItem>
        </Select>
    </div>
    <br/>
    <br/>
    <div className='table'>
        <Table stickyHeader className='driveOffTable'>
          <TableHead>
            <TableRow>
              <TableCell className='tableHeadID'>Transaction ID</TableCell>
              <TableCell className='tableHead'>Drive Off Time</TableCell>
              <TableCell className='tableHead'>Fuel Quantity (L)</TableCell>
              <TableCell className='tableHead'>Fuel Value (£)</TableCell>
              <TableCell className='tableHeadNo'>Pump No</TableCell>
              <TableCell className='tableHead'>Site Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {driveOffs.map((driveOff) => (
              <TableRow key={driveOff.fuelTransactionId}>
                <TableCell className='tableRowID'>{driveOff.fuelTransactionId}</TableCell>
                <TableCell className='tableRow'>{new Date(driveOff.paymentTime).toLocaleString()}</TableCell>
                <TableCell className='tableRow'>{driveOff.fuelQuantity} L</TableCell>
                <TableCell className='driveOffValue'>£{driveOff.fuelValue.toFixed(2)}</TableCell>
                <TableCell className='tableRowNo'>{driveOff.pumpNo}</TableCell>
                <TableCell className='tableRow'>{driveOff.siteName.toUpperCase()}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table> 
    </div>
    <br/>
    <div className='pagesNav'>
            <Pagination
                count={totalPages}
                page={page}
                onChange={(event, value) => setPage(value)}
            />
    </div>
    <div className='additionalInfo'>
            <Typography className='totalValue'>
                <p><p>Total Drive Off Value: </p>{new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(totalDriveOffValue)}</p>
            </Typography>
            <Typography className='percentage'>
                <p><p>Percentage of Drive Offs: </p>{percentage.toFixed(2)}%</p>
            </Typography>
    </div>
    {/* CSV Export Dialog */}
    <Dialog open={openCSVDialog} onClose={handleCSVDialogClose}>
        <DialogTitle>Export as CSV</DialogTitle>
        <DialogContent>
            <DialogContentText>
                Please enter how many rows you would like to export:
            </DialogContentText>
            <TextField
                autoFocus
                margin="dense"
                label="Rows to Export"
                type="number"
                fullWidth
                variant="standard"
                value={exportPerPage}
                onChange={(e) => setExportPerPage(Number(e.target.value))}
            />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCSVDialogClose}>Cancel</Button>
                <Button onClick={handleExportCSV}>Export</Button>
            </DialogActions>
    </Dialog>
    {/* PDF Export Dialog */}
    <Dialog open={openPDFDialog} onClose={handlePDFDialogClose}>
        <DialogTitle>Export as PDF</DialogTitle>
        <DialogContent>
            <DialogContentText>
                Please enter how many rows you would like to export:
            </DialogContentText>
            <TextField
                autoFocus
                margin="dense"
                label="Rows to Export"
                type="number"
                fullWidth
                variant="standard"
                value={exportPerPage}
                onChange={(e) => setExportPerPage(Number(e.target.value))}
            />
            </DialogContent>
            <DialogActions>
                <Button onClick={handlePDFDialogClose}>Cancel</Button>
                <Button onClick={handleExportPDF}>Export</Button>
            </DialogActions>
    </Dialog>
    </>
  );
};

export default DriveOffTable;