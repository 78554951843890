import React from "react";
import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Loader from "../../loader/Loader";

interface TransactionRequestBody {
  page: number;
  order: string;
  page_size: number;
  order_by: string;
}

const ProductSummary = () => {
  let Url: string = `${process.env.REACT_APP_REFUNDS_URL}/analytics/product_summary`;

  const [data, setData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const abortController = new AbortController();
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [order, setOrder] = useState<string>("acs");
  const [orderBy, setOrderBy] = useState<string>("num_transactions");

  const debounce = (func: Function, delay: number) => {
    let timer: NodeJS.Timeout;
    return (...args: any[]) => {
      clearTimeout(timer);
      timer = setTimeout(() => func(...args), delay);
    };
  };

  const handleNext = () => {
    abortController.abort();
    if (page < totalPages) {
      const newPage = page + 1;
      console.log(newPage);
      setPage(newPage);
    }
  };

  const handlePrev = () => {
    abortController.abort();
    if (page > 1) {
      const newPage = page - 1;
      setPage(newPage);
    }
  };

  const handlePageNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const number = parseInt(value, 10);
    console.log(number);
    if (!isNaN(number)) {
      if (number < 1) {
        setPage(1); // Minimum value
      } else if (number > totalPages + 1) {
        setPage(totalPages); // Maximum value
      } else {
        setPage(number);
      }
    } else {
      setPage(1); // Default to 1 if not a valid number
    }
    // setPage(parseInt(e.target.value))
  };

  const handlePageSizeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newSize = parseInt(e.target.value, 10);
    setPageSize(newSize);
    setPage(1);
  };
  const handleOrderChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setOrder(e.target.value);
    setPage(1);
  };
  const handleOrderByChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setOrderBy(e.target.value);
    setPage(1);
  };

  useEffect(() => {
    console.log("useeffect running");
    let timer: any = 0;
    if (pageSize > 0 && page > 0) {
      console.log(page, pageSize, totalPages, order, orderBy);

      timer = setTimeout(() => {
        getTableData(Url);
      }, 400);
    }
    return () => clearTimeout(timer);
  }, [page, pageSize, order, orderBy]);

  const getTableData = async (Url: string) => {
    let requestBody: TransactionRequestBody = {
      page: page,
      order: order,
      page_size: pageSize,
      order_by: orderBy,
    };

    console.log("body is here ", requestBody);
    let res: any = await axios
      .post(Url, requestBody, { signal: abortController.signal })
      .then((res) => {
        console.log("res is here ", res);
        console.log("========");
        setData(res?.data?.data);
        setTotalPages(res?.data.total_pages);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("ankit", err);
      });
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      //   fontWeight:600
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      fontWeight: 600,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  return (
    <div>
      {isLoading ? (
        <div>
          <Loader />
        </div>
      ) : (
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 700, maxHeight: "800px" }}
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>Product Name</StyledTableCell>
                <StyledTableCell align="right">
                  Discount Transactions
                </StyledTableCell>
                <StyledTableCell align="right">
                  Num Transactions
                </StyledTableCell>
                <StyledTableCell align="right">
                  Refund Transactions
                </StyledTableCell>
                <StyledTableCell align="right">Discount Value</StyledTableCell>
                <StyledTableCell align="right">Refund Value</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.map((row: any) => (
                  <StyledTableRow key={row.user_name}>
                    <StyledTableCell component="th" scope="row">
                      {row.product_name}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.num_dis_trns?.toFixed(3)}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.num_transactions?.toFixed(3)}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.num_ref_trns?.toFixed(3)}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.discount_value?.toFixed(3)}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.refund_value?.toFixed(3)}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",

                justifyContent: "space-around",
                padding: "1rem",
                width: "60%",
                borderTop: "1px solid #e0e0e0",
              }}
            >
              <div>
                Rows per page :
                <select
                  style={{
                    marginLeft: "0.5rem",
                    padding: "0.3rem",
                    border: "1px solid #ccc",
                    borderRadius: "3px",
                    outline: "none",
                  }}
                  value={pageSize}
                  onChange={handlePageSizeChange}
                >
                  {[10, 20, 50, 100].map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                Order :
                <select
                  style={{
                    marginLeft: "0.5rem",
                    padding: "0.3rem",
                    border: "1px solid #ccc",
                    borderRadius: "3px",
                    outline: "none",
                  }}
                  value={order}
                  onChange={handleOrderChange}
                >
                  {[
                    ["asc", "Ascending"],
                    ["desc", "Descending"],
                  ].map((size) => (
                    <option key={size[0]} value={size[0]}>
                      {size[1]}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                Order by :
                <select
                  style={{
                    marginLeft: "0.5rem",
                    padding: "0.3rem",
                    border: "1px solid #ccc",
                    borderRadius: "3px",
                    outline: "none",
                  }}
                  value={orderBy}
                  onChange={handleOrderByChange}
                >
                  {[
                    ["Product Name", "product_name"],
                    ["Discount Transactions", "num_dis_trns"],
                    ["Num Transactions", "num_transactions"],
                    ["Refund Transactions", "num_ref_trns"],
                    ["Discount Value", "discount_value"],
                    ["Refund Value", "refund_value"],
                  ].map((size) => (
                    <option key={size[0]} value={size[0]}>
                      {size[0]}
                    </option>
                  ))}
                </select>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 20,
                  alignItems: "center",
                }}
              >
                <button
                  style={{
                    padding: "8px 16px",
                    margin: "0 8px",
                    border: "none",
                    borderRadius: "3px",
                    background: `${page <= 1? '#D3D3D3' :"linear-gradient(135deg,rgb(138, 182, 233), #007BFF)"}`,
                    color: "white",
                    fontSize: "14px",
                    fontWeight: "600",
                    cursor: "pointer",
                    transition: "transform 0.2s, box-shadow 0.3s",
                    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                  }}
                  onMouseOver={(e) =>
                    page > 1 && (e.currentTarget.style.transform = "scale(1.05)")
                  }
                  onMouseOut={(e) =>
                    (e.currentTarget.style.transform = "scale(1)")
                  }
                  disabled={!!(page <= 1)}
                  onClick={handlePrev}
                >
                  &lt;
                </button>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                >
                  <span>
                    <input
                      // type="number"
                      style={{
                        width: "30px",
                        padding: "0.3rem",
                        textAlign: "center",
                        border: "1px solid #ccc",
                        marginLeft: "0.3rem",
                        borderRadius: "3px",
                        outline: "none",
                      }}
                      value={page}
                      onChange={handlePageNumber}
                    ></input>
                    {`  of ${totalPages}`}
                  </span>
                </div>
                <button
                  style={{
                    padding: "8px 16px",
                    margin: "0 8px",
                    border: "none",
                    borderRadius: "3px",
                    background: `${page >= totalPages? '#D3D3D3' :"linear-gradient(135deg,rgb(138, 182, 233), #007BFF)"}`,
                    color: "white",
                    fontSize: "14px",
                    fontWeight: "600",
                    cursor: "pointer",
                    transition: "transform 0.2s, box-shadow 0.3s",
                    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                  }}
                  onMouseOver={(e) =>
                    page < totalPages &&  (e.currentTarget.style.transform = "scale(1.05)")
                  }
                  onMouseOut={(e) =>
                    (e.currentTarget.style.transform = "scale(1)")
                  }
                  disabled={page >= totalPages ? true : false}
                  onClick={handleNext}
                >
                  &gt;
                </button>
              </div>
            </div>
          </div>
        </TableContainer>
      )}
    </div>
  );
};

export default ProductSummary;
