import * as React from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

interface RefundChartProps {
  children?: React.ReactNode;
  data: string;
}
const RefundChart: React.FC<any> = ({
  reportdata,
  params,
  reportType,
  chartType,
  categories,
  extraParams,
  selectedStore,
}) => {
  console.log(categories)
  const getTitle: any = (p: any) => {
    if (reportType == "type_trend")
      return `${params} Initiated`.toLocaleUpperCase();
    if (reportType == "reason_freq") return `${params}s`.toLocaleUpperCase();
    if (reportType == "reason_trend")
      return `${extraParams}s (${params}s)`.toLocaleUpperCase();
    if (reportType == "each_manager_trend")
      return `${extraParams?.split("@")[0].split(".")[0]} ${
        extraParams?.split("@")[0].split(".")[1]
      }'s ${params}s`.toLocaleUpperCase();
    if (reportType == "shop_trend")
      return `${selectedStore} (${params}s)`.toLocaleUpperCase();
    if (reportType == "shop_hourly_trend")
      return `${selectedStore} hourly trend`.toLocaleUpperCase();
    return "Report";
  };
  // console.log(Object.keys(reportdata))
  function objectToValuesArray(obj: { [key: string]: any }): any[] {
    const valuesArray: any[] = [];
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        valuesArray.push(obj[key]);
      }
    }
    return valuesArray;
  }

  let chart = chartType();

  const sortDateObject = (
    data: Record<string, number>
  ): Record<string, number> => {
    const entries = Object.entries(data);

    const sortedEntries = entries.sort(([dateA], [dateB]) => {
      const formattedDateA = new Date(
        dateA.split("/").reverse().join("-")
      ).getTime();
      const formattedDateB = new Date(
        dateB.split("/").reverse().join("-")
      ).getTime();

      return formattedDateA - formattedDateB; 
    });

    return Object.fromEntries(sortedEntries);
  };

  const options: any = {
    series: [
      {
        data: Object.values(sortDateObject(reportdata)),
        // name: "Name will come here",
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1, // Horizontal gradient
          },
          stops: [
            [0.7, "#bf9dfa"], // Blue color on the left
            [1, "#f299ee"], // Pink color on the right
          ],
        },
        color: "#bf9dfa",
        marker: {
          enabled: false, // Disable points
        },
        // color: '#91a5ff',
      },
    ],

    backgroundColor: "#ffffff", // Light mode background

    borderWidth: 5,
    plotBorderColor: {
      linearGradient: [0, 0, 0, 1],
      stops: [
        [0, 0.4, 0.2, "#ff69b4"], // Pink color
        [1, "#ffffff"], // White color
      ],
    },
    credits: {
      position: {
        align: "left",
        verticalAlign: "bottom",
        x: 10,
        y: -10,
      },
    },
    labels: {
      align: "right",
      x: -10,
      y: 0,
    },
    plotBorderWidth: 5,

    chart: {
      height: 450,
      spacingBottom: 0,
      spacingLeft: 2,
      spacingRight: 5,
      spacingTop: 30,
      width: null,

      type: `${chart}`,
      // type: 'area',
      zooming: {
        type: "x",
      },

      panning: true,
      panKey: "shift",
      scrollablePlotArea: {
        minWidth: 50,
      },
      borderWidth: 4,
      borderRadius: 4,
      borderColor: {
        linearGradient: {
          x1: 1,
          y1: 0.5,
          x2: 0,
          y2: 1,
        },
        stops: [
          [0, "#222cf2"],
          [0.7, "#c9d3ff"], // Blue color at the top
          [0.9, "#e8bed2"], // Pink color at the bottom
        ],
        color: "#0000ff",

        // stops: [
        //     [0, '#ff69b4'], // Pink color
        //     [0.5, '#ffffff']  // White color
        // ]
      },
    },
    title: {
      // text: `${params} Initiated`.toLocaleUpperCase(),
      text: getTitle(params).toLocaleUpperCase(),
      floating: true,
      align: "center",
      x: -0,
      y: 30,
    },

    xAxis: {
      labels: {
        format: "{value}",
      },
      categories: Object.keys(sortDateObject(reportdata)),
      minRange: 1,
      title: {
        text: "Dates",
      },
      accessibility: {
        rangeDescription: "Range: 0 to 187.8km.",
      },
    },
    tooltip: {
      headerFormat: "Date: {point.x:.1f} £<br>",
      pointFormat: "money {point.y} ",
      // shared: true
    },
    yAxis: {
      startOnTick: true,
      endOnTick: false,
      maxPadding: 0.3,
      title: {
        text: "Amount",
      },
      labels: {
        format: "£{value}",
      },
      accessibility: {
        description: "Elevation",
        rangeDescription: "Range: 0 to 1,553 meters",
      },
      // Ensure the y-axis is visible
    },
  };

  const getOptions = (reportType: string) => {
    if (reportType == "managers_barplot") return optionForManagerBarPlot;
    return options;
  };

  const optionForManagerBarPlot = {
    chart: {
      type: "column",
      height: 850,
      spacingTop: 30,
      spacingBottom: 0,
      panning: true,
      panKey: "shift",
      scrollablePlotArea: {
        minWidth: 50,
      },
      borderWidth: 4,
      borderRadius: 4,
      borderColor: {
        linearGradient: {
          x1: 1,
          y1: 0.5,
          x2: 0,
          y2: 1,
        },
        stops: [
          [0, "#222cf2"],
          [0.7, "#c9d3ff"], // Blue color at the top
          [0.9, "#e8bed2"], // Pink color at the bottom
        ],
        color: "#0000ff",

        // stops: [
        //     [0, '#ff69b4'], // Pink color
        //     [0.5, '#ffffff']  // White color
        // ]
      },
    },
    title: {
      text: "Manager Bar Plot",
    },
    
    xAxis: {
      categories: categories,
      crosshair: true,
      accessibility: {
        description: "Countries",
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "Amount",
      },
    },
    tooltip: {
      valuePrefix: "£ ",
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
      series: {
        groupPadding: 0.2, // Adjust the distance between groups of bars
        pointPadding: 0.1, // Adjust the distance between individual bars
        pointWidth: 30, // Set the width of the bars (in pixels)
      },
    },
    series: reportdata,
  };

  return (
    <div style={{ minHeight: 450 }}>
      <div>
        {reportdata && (
          <HighchartsReact
            highcharts={Highcharts}
            options={getOptions(reportType)}
          />
        )}
        <p>The data in the graph is the live data </p>
      </div>
    </div>
  );
};

export default RefundChart;
