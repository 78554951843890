import React from 'react';
import './styles/Footer.scss'

const Footer: React.FC = () => {
    return(
        <footer
            className='footer'
            style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                backgroundColor: "#4f5a75",
            }}
        >
            <p style={{ color: "#fff" }}>
                Developed by{" "}
                <a href="https://nitrosolutions.co.uk" target="_blank" style={{color:"#fff"}}>
                    Nitro Solutions
                </a>
            </p>
        </footer>
    );
};

export default Footer;