import React, { useEffect, useState } from 'react';
import { BarChart } from '@mui/x-charts/BarChart';

interface StoresData {
    rank: number;
    site_name: string;
    total_sales: number;
  }

interface StoresBarChartProps {
  storesData: StoresData[];
}

const SalesBarChart: React.FC<StoresBarChartProps> = ({ storesData }) => {
  const [chartWidth, setChartWidth] = useState<number>(550);
  const [chartHeight, setChartHeight] = useState<number>(600);
  const siteName = storesData.map((item) => item.site_name);
  const totalSales = storesData.map((item) => item.total_sales);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setChartWidth(330);
        setChartHeight(400);
      } 
      else {
        setChartWidth(550);
        setChartHeight(600);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial call

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <BarChart
      xAxis={[
        {
          id: 'sitesData',
          data: siteName,
          scaleType: 'band',
        },
      ]}
      series={[
        {
          data: totalSales,
          label: 'Total Sales (£)',
          color: '#3452A0',
        },
      ]}
      width={chartWidth}
      height={chartHeight}
      margin={{
        left: 80,
        right: 80,
        top: 80,
        bottom: 80,
      }}
    />
  );
};

export default SalesBarChart;