import  * as React from 'react';
import { useEffect, useState } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, 
  Pagination, CircularProgress, Container, Select, MenuItem, FormControl, InputLabel, Box, Typography
} from '@mui/material';

interface Rule {
  match_1: string[];
  match_2: string[];
  support: number;
  confidence: number;
  lift: number;
}
interface ApiResponse {
    total: number;
    pages: number;
    current_page: number;
    data: Rule[];
}
const ProductAffinity: React.FC = () => {
  const [rules, setRules] = useState<Rule[]>([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState<number>(1);
  const [month, setMonth] = useState<string>('7'); // Default to July
  const [errorMessage, setErrorMessage] = useState('');
  const url =`${process.env.REACT_APP_API_URL}/api/products_affinity?page=${page}&month=${month}`;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setErrorMessage('');
      try {
        const response = await fetch(url);
        const result: ApiResponse = await response.json();
        setRules(result.data);
      } catch (error:any) {
        if (error.response && error.response.data.message) {
          setErrorMessage(error.response.data.message);
        } else {
          setErrorMessage('An error occurred while fetching data.');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [page, month]);

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleMonthChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setMonth(event.target.value as string);
    setPage(1); // Reset to the first page when month changes
  };

  return (
    <Container>
      <Box display="flex" justifyContent="space-between" alignItems="center" mt={4} mb={4}>
        <FormControl variant="outlined">
          <InputLabel id="month-label">Month</InputLabel>
          <Select
            labelId="month-label"
            value={month}
            onChange={(e) => setMonth(e.target.value)}
            label="Month"
          >
            <MenuItem value="3">March/2024</MenuItem>
            <MenuItem value="4">April/2024</MenuItem>
            <MenuItem value="5">May/2024</MenuItem>
            <MenuItem value="6">June/2024</MenuItem>
            <MenuItem value="7">July/2024</MenuItem>
            <MenuItem value="8">August/2024</MenuItem>
            <MenuItem value="9">September/2024</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {loading ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : errorMessage ? (
        <Typography color="error">{errorMessage}</Typography>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell><strong>Match 1</strong></TableCell>
                  <TableCell><strong>Match 2</strong></TableCell>
                  <TableCell><strong>Confidence</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rules.map((rule, index) => (
                  <TableRow key={index}>
                    <TableCell>{rule.match_1.join(', ')}</TableCell>
                    <TableCell>{rule.match_2.join(', ')}</TableCell>
                    <TableCell>{rule.confidence.toFixed(1)}%</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Box display="flex" justifyContent="center" mt={4}>
            <Pagination
              page={page}
              onChange={handlePageChange}
              color="primary"
            />
          </Box>
        </>
      )}
    </Container>
  );
};

export default ProductAffinity;